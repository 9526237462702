@font-face {
  font-family: 'myHelvitikaFont';
  src: url('../../fonts/HelveticaNeueLTPro-Md.woff');
}

@mixin dashboard_left_design() {
  background-color: #ffff;
  border-radius: 10px;
  position: relative;
  // height: 95%;
}

@mixin dashboard_right_design() {
  background-color: #fff;
  border-radius: 10px;
  // border: 1px solid yellowgreen;
}

@mixin transaction_design($leftVal) {
  border-radius: 10px;
  padding: 10px 0px;
  width: 100%;
  // height: 68%;
  // position: relative;
  // top: 25%;
  // left: $leftVal;
}

@mixin text_design($fontSize, $Color, $letterSpacing) {
  font-size: $fontSize; // 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: $letterSpacing; //normal;
  color: $Color; // #f57a08;
}

@mixin circle_Design($color, $topVal, $leftVal) {
  width: 100%;
  height: auto;
  .sales__details--1,
  .sales__details--2 {
    height: 125px;
    width: 125px;
    border-radius: 50%;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.14);
    border: solid 1px $color;
    background-color: #ffffff;
    position: relative;
    top: $topVal;
    left: $leftVal;
    .name {
      color: $color;
    }
  }
}

@mixin transaction_name_design($fontSize, $color, $leftVal, $topValue) {
  @include text_design($fontSize, $color, normal);
  position: relative;
  left: $leftVal;
  top: $topValue;
}

@mixin transaction_arrow_design($color, $leftVal) {
  @include text_design(15px, $color, normal);
  position: relative;
  left: $leftVal;
  margin-left: 5px;
}

@mixin button_design($color_1, $color_2) {
  width: 200px;
  // height: 40px;
  border-radius: 6px;
  padding: 10px 0px;
  border: none;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1);
  background-image: linear-gradient(95deg, $color_1, $color_2);
  font-size: 14px;
  color: #ffffff;
}

@mixin age_graph_design($color) {
  border-radius: 22px;
  background-color: #f3f3f3;

  .genderBarInside {
    background-color: $color;
    border-radius: 22px;
    position: relative;
  }
}

@mixin age_design($topVal, $color) {
  padding: 0 15px 0 25px;
  position: relative;
  top: $topVal;

  .genderBar--1,
  .genderBar--2,
  .genderBar--3,
  .genderBar--4,
  .genderBar--5 {
    @include age_graph_design($color);
    // margin-bottom: 10px;
    .age_group {
      @include text_design(12px, #43424b, normal);
      position: absolute;
      z-index: 1;
      left: 10%;
      top: 23%;
    }
    .total_count {
      @include text_design(12px, #43424b, normal);
      position: absolute;
      right: 6%;
      top: 23%;
    }
  }
}

// * {box-sizing: border-box;}
.wrapper {
  display: grid !important;
  grid-template-columns: 60% 40% !important;
  margin-top: 3%;
  // grid-auto-rows: minmax(100%, auto);
  // position: relative;
  // top: 3%;
  //max-width: 100%;
  //grid-column-gap: 40px;

  .transaction_container,
  .sales__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 600px;
  }

  .sales_related_details {
    margin: 0% 2% 2% 2%;
    display: grid;
    grid-row-gap: 20px;
    grid-auto-rows: 180px;

    h5 {
      @include text_design(16px, hsl(0, 0%, 55%), 1px);
    }

    // started 1st div -------------->>>>
    .transaction__detail {
      @include dashboard_left_design();
      box-shadow: 0px 5px 10px 0px hsl(0, 0%, 90%);
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .stats-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(100%, auto);
        grid-column-gap: 30px;
      }

      img {
        padding: 0px 20px;
        height: 75px;
      }

      .__details {
        color: #ffffff;
        text-align: center;

        .__details--1 {
          font-size: 0.7em;
          margin-bottom: 0px;
        }
        .__details--2 {
          font-size: 1.7em;
          text-align: center;
          // margin-left: 5px;
        }
      }

      .total_transaction {
        display: flex;
        align-items: center;
        @include transaction_design(10%);
        // box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #1ca7ea, #0b59df);
      }

      .total_no_transaction {
        display: flex;
        align-items: center;
        @include transaction_design(5%);
        // box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #f8b90a, #e15d05);
      }
    }

    // started 2nd div ------->>>
    .sales__detail {
      @include dashboard_left_design();
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 30px;
      box-shadow: 0px 5px 10px 0px hsl(0, 0%, 90%);
      // height: 80%;

      .sales__wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(100%, auto);
        grid-column-gap: 30px;
      }

      .sales_heading {
        letter-spacing: 1px;
        color: hsl(0, 0%, 55%);
        // justify-self: center;
        // align-self: center;
      }

      .name {
        @include text_design(10px, #f57a08, normal);
        position: relative;
        left: 30%;
        top: 20%;
      }

      .total_amt {
        @include text_design(1.6em, #43424b, 1.2px);
        position: relative;
        top: 20%;
        left: 10%;
      }
    }

    .transaction_type {
      @include dashboard_left_design();
      box-shadow: 0px 5px 10px 0px hsl(0, 0%, 90%);
      display: flex;
      justify-content: center;
      padding: 0px 30px;

      .name {
        @include text_design(16px, hsl(0, 0%, 55%), 1px);
        margin-bottom: 1em;
      }

      .transaction_details {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-gap: 2px;
        grid-auto-rows: minmax(100%, auto);
        text-align: center;

        .transaction_info {
          @include text_design(28px, #43424b, 2.4px);
          position: relative;
          // left: 20%;
          top: 15%;
        }

        .transaction_details--1 {
          .transaction_name {
            @include transaction_name_design(16px, red, 0%, 10%);
          }

          .arrow {
            @include transaction_arrow_design(#67c560, 0%);
          }
        }

        .transaction_details--2 {
          .transaction_name {
            @include transaction_name_design(16px, #1bd097, 0%, 10%);
          }

          .arrow {
            @include transaction_arrow_design(#f81010, 0%);
          }
        }

        .transaction_details--3 {
          .transaction_name {
            @include transaction_name_design(16px, orange, 0%, 10%);
          }

          .arrow {
            @include transaction_arrow_design(#67c560, 0%);
          }
        }

        .transaction_details--4 {
          .transaction_name {
            @include transaction_name_design(16px, darkgrey, 0%, 10%);
          }

          .arrow {
            @include transaction_arrow_design(#67c560, 0%);
          }
        }
      }
    }

    .current_sales {
      @include dashboard_left_design();
      box-shadow: 0px 5px 10px 0px hsl(0, 0%, 90%);
      padding: 1.6em 30px;

      .sales_details {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;

        .current_balance {
          width: auto;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .sales_name {
            @include text_design(1rem, hsl(0, 0%, 55%), 2.4px);
            margin: 0px;
          }

          .balance {
            @include text_design(1.4rem, #2591ff, 1.4px);
            margin: 0px;
          }
        }

        .btn {
          width: 100%;

          a {
            text-decoration: none;
          }

          .btn--1 {
            @include button_design(#1ca7ea, #0b59df);
            margin-right: 5px;
          }

          .btn--2 {
            margin-left: 5px;
            @include button_design(#f8b90a, #e15d05);
          }
        }
      }
    }
  }

  .dashBoardRightPart {
    margin: 0% 2% 2% 2%;

    .dashboardFeedbacks {
      height: 50vh !important;
      padding: 40px 20px;
      box-shadow: 0px 5px 10px 0px hsl(0, 0%, 90%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h4 {
        margin-bottom: 1em;
      }

      @include dashboard_left_design();
      .dashboardFeedbackHeading {
        @include text_design(16px, hsl(0, 0%, 55%), 2.2px);
      }
      .dashboardFeedbackContainer {
        overflow: auto;

        .feedbackChild {
          padding: 2%;
          border: 1px solid hsl(0, 0%, 92%);

          .feedbackChildFirst {
            display: grid;
            grid-template-columns: 50% 50%;

            .feedbackComment {
              height: 20px;
              font-size: 1em;
              color: #43424b;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .feedbackStars {
              vertical-align: top !important;
              color: #d4af37;
              font-size: 1.2rem;
              text-align: right;
            }
            img {
              color: #17df09;
            }
          }
          .feedbackUser {
            margin-top: 5px;
            color: #43424b;
            font-size: 12px;
            opacity: 0.6 !important;
          }
          .feedbackVisited {
            font-size: 10px !important;
            color: #43424b;
            font-weight: normal;
          }
        }
      }
      .viewMoreFeedbacksRoute {
        color: #999;
        margin: 0px auto;
        background: none;
        border: none;

        &:hover {
          color: #2591ff;
        }
      }
      /* scrollbars*/
      /* width */
      .dashboardFeedbackContainer::-webkit-scrollbar {
        box-shadow: inset 0 0 1px grey;
        width: 2px;
      }

      /* Track */
      .dashboardFeedbackContainer::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px grey;
        border-radius: 10px;
      }

      /* Handle */
      .dashboardFeedbackContainer::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
      }

      /* Handle on hover */
      .dashboardFeedbackContainer::-webkit-scrollbar-thumb:hover {
        background-image: linear-gradient(to bottom, #1ca7ea, #0b59df);
      }
    }
    .dashboardAdvertisment {
      margin-top: 2%;
      height: 350px !important;
      padding: 2%;

      @include dashboard_left_design();
      .dashboardAdvertismentHeading {
        padding-left: 5%;
        padding-top: 1%;
        @include text_design(16px, #43424b, 2.2px);
      }
      .dashboardLiveAdvertismentImage {
        padding: 2%;
        width: 300px;
        height: 300px;
      }
      .dashboardNoAdvertisment {
        padding: 5%;
        .dashboardNoAdvertismentBlueText {
          color: #2591ff;
          cursor: pointer;
        }
      }
    }
  }

  .advert {
    .liveBox {
      margin-left: 0px;
      margin-right: 0px;
      box-shadow: 0px 5px 10px 0px hsl(0, 0%, 90%);
    }
  }
}
