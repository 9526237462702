.profileWrapper {
  width: 100%;
  margin-top: 2%;
  position: relative;
  height: calc(100vh - 180px);

  .gridBoxProfile {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
  }

  .profileWrapperInner {
    width: 100%;
    max-height: calc(100vh - 180px);
    overflow-y: auto;
    min-height: 1%;

    .profileInnerBox {
      width: 90%;
      height: 125px;
      margin: 0% auto;
      overflow-y: scroll;
      cursor: pointer;
      margin-bottom: 2% !important;
    }
    .profileInnerBox::-webkit-scrollbar {
      width: 0 !important;
    }
    .profileTypeHeading {
      font-size: 100%;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.8px;
      margin: 0 5% 2%;
      color: #000;
      text-align: center;
    }
    .companyName {
      font-size: 100%;
      font-weight: 440;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.4px;
      color: #43424b;
      margin-top: 5%;
      margin-left: 10%;
      margin-right: 3%;
    }
    .companyEmail {
      opacity: 0.6;
      font-size: 80%;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.94;
      letter-spacing: normal;
      text-align: left;
      color: #43424b;
      margin-top: 4%;
      margin-left: 10%;
    }
    .companyContact {
      opacity: 0.6;
      font-size: 80%;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.94;
      letter-spacing: normal;
      text-align: left;
      color: #43424b;
      margin-top: 3%;
      margin-left: 10%;
    }

    /*--------Brand----------*/
    .brandBox {
      display: grid;
      grid-template-columns: 40% 60%;
    }
    .brandLogo img {
      width: 100px;
      vertical-align: center;
      margin-left: 10%;
      margin-top: 11px;
    }
    .brandInfo {
      margin-right: 3%;
    }
    .brandName {
      font-size: 100%;
      font-weight: 440;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.4px;
      color: #43424b;
      margin-top: 10%;
      margin-right: 3%;
    }
    .brandCategory {
      margin-top: 6.8%;
      opacity: 0.6;
      font-size: 90%;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.94;
      letter-spacing: normal;
      text-align: left;
      color: #43424b;
    }
    .brandCategoryName {
      font-size: 90%;
      font-weight: 450;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #2591ff;
      margin-left: 5%;
    }

    /*--------Store----------*/
    .storeName {
      font-size: 100%;
      font-weight: 440;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.4px;
      color: #43424b;
      margin-top: 5%;
      margin-left: 10%;
      margin-right: 3%;
    }
    .storeCompany {
      font-size: 90%;
      font-weight: 440;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.4px;
      color: #2591ff;
      margin-top: 2%;
      margin-left: 10%;
      margin-right: 3%;
    }
    .storeAddress {
      opacity: 0.6;
      font-size: 80%;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.94;
      letter-spacing: normal;
      text-align: left;
      color: #43424b;
      margin-top: 3%;
      margin-left: 10%;
    }
  }
  .addButton {
    position: fixed !important;
    bottom: 30px;
    right: 30px;
    z-index: 3;
    width: 5%;
  }
  .addButton img {
    width: 100%;
    cursor: pointer;
  }

  .addOptionsStore {
    margin-top: -25% !important;
    position: fixed !important;
    bottom: 5%;
    right: 7.5%;
    height: 5%;
    z-index: 3;
    width: 10%;
    border: none;
    border-radius: 6px;
    background-color: #2591ff;
    font-size: 75%;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .addOptionsCompany {
    margin-top: -25% !important;
    position: fixed !important;
    bottom: 12%;
    right: 7.5%;
    z-index: 3;
    width: 10%;
    height: 5%;
    border: none;
    border-radius: 6px;
    background-color: #2591ff;
    font-size: 75%;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .addOptionsBackground {
  }
  .addOptionsBackground {
    position: fixed !important;
    right: -15%;
    bottom: -40%;
    z-index: 2;
    width: 40%;
    height: 80%;
  }

  /*-----------Popups--------------*/
  .popup-content {
    // width: 60% !important;
  }

  .popupModalHeading {
    width: 100%;
    font-size: 120%;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.8px;

    color: #2591ff;
    margin-bottom: 5%;
  }
  .popupModalHeadingInner {
    margin-right: 5%;
    margin-left: 40%;
  }
  .popupModalEditButton {
  }
  .popupModalEditButton img {
    cursor: pointer;
    width: 2%;
    margin-top: -0.5%;
  }
  .popupModalGrids {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 2%;
  }

  .popupModalGridInner input {
    width: 70%;
    height: 40px;
    padding-left: 10px;
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    margin-left: 15%;
    font-size: 100%;
    font-weight: 420;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #43424b;
    margin-bottom: 5%;
  }

  .popupModalGridInner select {
    width: 70%;
    height: 40px;
    padding-left: 10px;
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    margin-left: 15%;
    font-size: 100%;
    font-weight: 420;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #43424b;
    margin-bottom: 5%;
  }

  .popupModalGridInner textarea {
    width: 70%;
    padding-left: 10px;
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    margin-left: 15%;
    font-size: 100%;
    font-weight: 420;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #43424b;
    margin-bottom: 5%;
  }

  .popupModalLabel {
    opacity: 0.4;
    font-size: 90%;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-left: 16%;
    color: #43424b;
    margin-bottom: 1%;
  }

  .popupModalSubmitButton {
    width: 20%;
    padding: 0.8%;
    border-radius: 5%;
    border: none;
    background-image: linear-gradient(259deg, #1ca7ea, #0b59df);
    font-size: 75%;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-left: 40%;
  }
  .close {
    width: 10%;
    cursor: pointer;
    position: absolute;
    display: block;
    right: -5%;
    top: -6%;
    font-size: 24px;
    opacity: 1 !important;
  }
}
