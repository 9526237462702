.legal {
  .buttonGroup {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    padding-top: 3%;
  }
  .buttonLegal {
    width: 80%;
    margin: 0 auto;

    height: 30px;
    border: none;
    border-radius: 4px;
    background-image: linear-gradient(259deg, #1ca7ea, #0b59df);

    font-size: 70%;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .buttonLegalGreen {
    background-image: linear-gradient(259deg, #54942f, #0d6805);
  }
  .contentLegal {
    color: black !important;
    width: 80%;
    margin: 0% auto;
  }
}
