@import url('https://themes.googleusercontent.com/fonts/css?kit=QRM1DB91dRelHylHyc1ZatW6z5wzxkD6lcJ7I7ut-Ef3rGVtsTkPsbDajuO5ueQw');
.lst-kix_list_4-1 > li {
  counter-increment: lst-ctn-kix_list_4-1;
}

ol.lst-kix_list_7-0 {
  list-style-type: none;
}

ul.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_6-1 > li {
  counter-increment: lst-ctn-kix_list_6-1;
}

.lst-kix_list_8-1 > li {
  counter-increment: lst-ctn-kix_list_8-1;
}

ol.lst-kix_list_8-2.start {
  counter-reset: lst-ctn-kix_list_8-2 0;
}

ol.lst-kix_list_3-1.start {
  counter-reset: lst-ctn-kix_list_3-1 0;
}

ul.lst-kix_list_9-3 {
  list-style-type: none;
}

ul.lst-kix_list_9-4 {
  list-style-type: none;
}

ul.lst-kix_list_9-1 {
  list-style-type: none;
}

ol.lst-kix_list_6-6.start {
  counter-reset: lst-ctn-kix_list_6-6 0;
}

ul.lst-kix_list_9-2 {
  list-style-type: none;
}

ul.lst-kix_list_9-7 {
  list-style-type: none;
}

ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0;
}

ul.lst-kix_list_9-8 {
  list-style-type: none;
}

ul.lst-kix_list_9-5 {
  list-style-type: none;
}

.lst-kix_list_7-0 > li {
  counter-increment: lst-ctn-kix_list_7-0;
}

ul.lst-kix_list_9-6 {
  list-style-type: none;
}

ol.lst-kix_list_7-5 {
  list-style-type: none;
}

ul.lst-kix_list_1-3 {
  list-style-type: none;
}

ol.lst-kix_list_7-6 {
  list-style-type: none;
}

ul.lst-kix_list_1-4 {
  list-style-type: none;
}

ol.lst-kix_list_7-7 {
  list-style-type: none;
}

ul.lst-kix_list_1-1 {
  list-style-type: none;
}

ol.lst-kix_list_7-8 {
  list-style-type: none;
}

ul.lst-kix_list_1-2 {
  list-style-type: none;
}

ol.lst-kix_list_7-1 {
  list-style-type: none;
}

ul.lst-kix_list_1-7 {
  list-style-type: none;
}

ol.lst-kix_list_7-2 {
  list-style-type: none;
}

ul.lst-kix_list_9-0 {
  list-style-type: none;
}

ul.lst-kix_list_1-8 {
  list-style-type: none;
}

ol.lst-kix_list_7-3 {
  list-style-type: none;
}

ul.lst-kix_list_1-5 {
  list-style-type: none;
}

ol.lst-kix_list_7-4 {
  list-style-type: none;
}

ul.lst-kix_list_1-6 {
  list-style-type: none;
}

.lst-kix_list_4-3 > li {
  counter-increment: lst-ctn-kix_list_4-3;
}

ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}

ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}

ol.lst-kix_list_8-8.start {
  counter-reset: lst-ctn-kix_list_8-8 0;
}

.lst-kix_list_3-2 > li {
  counter-increment: lst-ctn-kix_list_3-2;
}

.lst-kix_list_7-2 > li {
  counter-increment: lst-ctn-kix_list_7-2;
}

ol.lst-kix_list_8-7.start {
  counter-reset: lst-ctn-kix_list_8-7 0;
}

.lst-kix_list_5-0 > li:before {
  content: '\002794  ';
}

ol.lst-kix_list_6-0 {
  list-style-type: none;
}

ol.lst-kix_list_6-1 {
  list-style-type: none;
}

.lst-kix_list_5-3 > li:before {
  content: '\0025cb  ';
}

.lst-kix_list_5-2 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_8-3 > li {
  counter-increment: lst-ctn-kix_list_8-3;
}

.lst-kix_list_5-1 > li:before {
  content: '\0025c6  ';
}

.lst-kix_list_5-7 > li:before {
  content: '\0025c6  ';
}

.lst-kix_list_5-6 > li:before {
  content: '\0025cb  ';
}

.lst-kix_list_5-8 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_6-6 {
  list-style-type: none;
}

ol.lst-kix_list_6-7 {
  list-style-type: none;
}

.lst-kix_list_5-4 > li:before {
  content: '\0025c6  ';
}

ol.lst-kix_list_6-8 {
  list-style-type: none;
}

.lst-kix_list_5-5 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_6-2 {
  list-style-type: none;
}

ol.lst-kix_list_6-3 {
  list-style-type: none;
}

ol.lst-kix_list_6-4 {
  list-style-type: none;
}

ol.lst-kix_list_6-5 {
  list-style-type: none;
}

.lst-kix_list_6-1 > li:before {
  content: '' counter(lst-ctn-kix_list_6-1, lower-roman) ') ';
}

.lst-kix_list_6-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_6-3, lower-latin) ') ';
}

.lst-kix_list_6-5 > li {
  counter-increment: lst-ctn-kix_list_6-5;
}

.lst-kix_list_6-8 > li {
  counter-increment: lst-ctn-kix_list_6-8;
}

.lst-kix_list_6-0 > li:before {
  content: '' counter(lst-ctn-kix_list_6-0, lower-latin) ') ';
}

.lst-kix_list_6-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_6-4, lower-roman) ') ';
}

.lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}

ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}

.lst-kix_list_3-6 > li {
  counter-increment: lst-ctn-kix_list_3-6;
}

.lst-kix_list_6-2 > li:before {
  content: '' counter(lst-ctn-kix_list_6-2, decimal) ') ';
}

ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}

.lst-kix_list_6-8 > li:before {
  content: '' counter(lst-ctn-kix_list_6-8, decimal) '. ';
}

.lst-kix_list_6-5 > li:before {
  content: '(' counter(lst-ctn-kix_list_6-5, decimal) ') ';
}

.lst-kix_list_6-7 > li:before {
  content: '' counter(lst-ctn-kix_list_6-7, lower-roman) '. ';
}

.lst-kix_list_6-6 > li:before {
  content: '' counter(lst-ctn-kix_list_6-6, lower-latin) '. ';
}

.lst-kix_list_2-7 > li:before {
  content: '\0025c6  ';
}

.lst-kix_list_7-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_7-4, lower-latin) ') ';
}

.lst-kix_list_7-6 > li:before {
  content: '' counter(lst-ctn-kix_list_7-6, decimal) '. ';
}

.lst-kix_list_2-5 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_6-2.start {
  counter-reset: lst-ctn-kix_list_6-2 0;
}

.lst-kix_list_7-2 > li:before {
  content: '' counter(lst-ctn-kix_list_7-2, lower-roman) ') ';
}

.lst-kix_list_7-6 > li {
  counter-increment: lst-ctn-kix_list_7-6;
}

.lst-kix_list_8-6 > li {
  counter-increment: lst-ctn-kix_list_8-6;
}

ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}

.lst-kix_list_10-1 > li:before {
  content: '\0025c6  ';
}

.lst-kix_list_13-7 > li:before {
  content: '-  ';
}

ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}

.lst-kix_list_7-7 > li {
  counter-increment: lst-ctn-kix_list_7-7;
}

.lst-kix_list_7-8 > li:before {
  content: '' counter(lst-ctn-kix_list_7-8, lower-roman) '. ';
}

ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}

.lst-kix_list_4-7 > li {
  counter-increment: lst-ctn-kix_list_4-7;
}

.lst-kix_list_10-7 > li:before {
  content: '\0025c6  ';
}

.lst-kix_list_7-8 > li {
  counter-increment: lst-ctn-kix_list_7-8;
}

.lst-kix_list_10-5 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_10-3 > li:before {
  content: '\0025cb  ';
}

ul.lst-kix_list_11-7 {
  list-style-type: none;
}

ul.lst-kix_list_11-6 {
  list-style-type: none;
}

.lst-kix_list_4-1 > li:before {
  content: '' counter(lst-ctn-kix_list_4-1, lower-roman) ') ';
}

ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0;
}

ul.lst-kix_list_11-5 {
  list-style-type: none;
}

ul.lst-kix_list_11-4 {
  list-style-type: none;
}

ul.lst-kix_list_11-3 {
  list-style-type: none;
}

ul.lst-kix_list_11-2 {
  list-style-type: none;
}

ul.lst-kix_list_11-1 {
  list-style-type: none;
}

ul.lst-kix_list_11-0 {
  list-style-type: none;
}

.lst-kix_list_9-2 > li:before {
  content: '-  ';
}

.lst-kix_list_4-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_4-3, lower-latin) ') ';
}

.lst-kix_list_4-5 > li:before {
  content: '(' counter(lst-ctn-kix_list_4-5, decimal) ') ';
}

ul.lst-kix_list_11-8 {
  list-style-type: none;
}

.lst-kix_list_3-5 > li {
  counter-increment: lst-ctn-kix_list_3-5;
}

.lst-kix_list_9-0 > li:before {
  content: '-  ';
}

.lst-kix_list_3-4 > li {
  counter-increment: lst-ctn-kix_list_3-4;
}

ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}

.lst-kix_list_6-4 > li {
  counter-increment: lst-ctn-kix_list_6-4;
}

.lst-kix_list_9-6 > li:before {
  content: '-  ';
}

.lst-kix_list_9-4 > li:before {
  content: '-  ';
}

.lst-kix_list_11-3 > li:before {
  content: '\0025cb  ';
}

.lst-kix_list_6-3 > li {
  counter-increment: lst-ctn-kix_list_6-3;
}

ul.lst-kix_list_2-8 {
  list-style-type: none;
}

ol.lst-kix_list_8-8 {
  list-style-type: none;
}

.lst-kix_list_12-3 > li:before {
  content: '\0025cb  ';
}

ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0;
}

.lst-kix_list_11-5 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_6-1.start {
  counter-reset: lst-ctn-kix_list_6-1 0;
}

ol.lst-kix_list_8-4 {
  list-style-type: none;
}

.lst-kix_list_12-1 > li:before {
  content: '\0025c6  ';
}

ul.lst-kix_list_2-2 {
  list-style-type: none;
}

ol.lst-kix_list_8-5 {
  list-style-type: none;
}

ul.lst-kix_list_2-3 {
  list-style-type: none;
}

ol.lst-kix_list_8-6 {
  list-style-type: none;
}

ul.lst-kix_list_2-0 {
  list-style-type: none;
}

ol.lst-kix_list_8-7 {
  list-style-type: none;
}

ul.lst-kix_list_2-1 {
  list-style-type: none;
}

ol.lst-kix_list_8-0 {
  list-style-type: none;
}

.lst-kix_list_9-8 > li:before {
  content: '-  ';
}

ul.lst-kix_list_2-6 {
  list-style-type: none;
}

ol.lst-kix_list_8-1 {
  list-style-type: none;
}

.lst-kix_list_1-1 > li:before {
  content: '\0025c6  ';
}

ul.lst-kix_list_2-7 {
  list-style-type: none;
}

.lst-kix_list_11-7 > li:before {
  content: '\0025c6  ';
}

ol.lst-kix_list_8-2 {
  list-style-type: none;
}

ul.lst-kix_list_2-4 {
  list-style-type: none;
}

ol.lst-kix_list_8-3 {
  list-style-type: none;
}

ul.lst-kix_list_2-5 {
  list-style-type: none;
}

ul.lst-kix_list_10-0 {
  list-style-type: none;
}

.lst-kix_list_8-5 > li {
  counter-increment: lst-ctn-kix_list_8-5;
}

.lst-kix_list_1-3 > li:before {
  content: '\0025cb  ';
}

.lst-kix_list_13-3 > li:before {
  content: '-  ';
}

ul.lst-kix_list_10-8 {
  list-style-type: none;
}

.lst-kix_list_4-8 > li {
  counter-increment: lst-ctn-kix_list_4-8;
}

ul.lst-kix_list_10-7 {
  list-style-type: none;
}

.lst-kix_list_1-7 > li:before {
  content: '\0025c6  ';
}

ul.lst-kix_list_10-6 {
  list-style-type: none;
}

ul.lst-kix_list_10-5 {
  list-style-type: none;
}

ul.lst-kix_list_10-4 {
  list-style-type: none;
}

ul.lst-kix_list_10-3 {
  list-style-type: none;
}

.lst-kix_list_1-5 > li:before {
  content: '\0025cf  ';
}

ul.lst-kix_list_10-2 {
  list-style-type: none;
}

ul.lst-kix_list_10-1 {
  list-style-type: none;
}

.lst-kix_list_13-5 > li:before {
  content: '-  ';
}

.lst-kix_list_12-5 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0;
}

.lst-kix_list_12-7 > li:before {
  content: '\0025c6  ';
}

.lst-kix_list_2-1 > li:before {
  content: '\0025c6  ';
}

ol.lst-kix_list_6-0.start {
  counter-reset: lst-ctn-kix_list_6-0 0;
}

.lst-kix_list_2-3 > li:before {
  content: '\0025cb  ';
}

.lst-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2;
}

.lst-kix_list_13-1 > li:before {
  content: '-  ';
}

ol.lst-kix_list_3-1 {
  list-style-type: none;
}

ol.lst-kix_list_3-2 {
  list-style-type: none;
}

.lst-kix_list_3-1 > li {
  counter-increment: lst-ctn-kix_list_3-1;
}

ol.lst-kix_list_3-3 {
  list-style-type: none;
}

ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}

ol.lst-kix_list_3-4 {
  list-style-type: none;
}

ol.lst-kix_list_3-0 {
  list-style-type: none;
}

.lst-kix_list_7-1 > li {
  counter-increment: lst-ctn-kix_list_7-1;
}

.lst-kix_list_3-0 > li:before {
  content: '' counter(lst-ctn-kix_list_3-0, lower-latin) ') ';
}

ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0;
}

ul.lst-kix_list_5-7 {
  list-style-type: none;
}

ul.lst-kix_list_5-8 {
  list-style-type: none;
}

.lst-kix_list_3-1 > li:before {
  content: '' counter(lst-ctn-kix_list_3-1, lower-roman) ') ';
}

.lst-kix_list_3-2 > li:before {
  content: '' counter(lst-ctn-kix_list_3-2, decimal) ') ';
}

ul.lst-kix_list_5-5 {
  list-style-type: none;
}

ul.lst-kix_list_5-6 {
  list-style-type: none;
}

.lst-kix_list_8-1 > li:before {
  content: '' counter(lst-ctn-kix_list_8-1, lower-roman) ') ';
}

.lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}

.lst-kix_list_8-2 > li:before {
  content: '' counter(lst-ctn-kix_list_8-2, decimal) ') ';
}

.lst-kix_list_6-0 > li {
  counter-increment: lst-ctn-kix_list_6-0;
}

.lst-kix_list_8-0 > li {
  counter-increment: lst-ctn-kix_list_8-0;
}

.lst-kix_list_3-5 > li:before {
  content: '(' counter(lst-ctn-kix_list_3-5, decimal) ') ';
}

ul.lst-kix_list_5-0 {
  list-style-type: none;
}

.lst-kix_list_3-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_3-4, lower-roman) ') ';
}

ul.lst-kix_list_5-3 {
  list-style-type: none;
}

.lst-kix_list_3-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_3-3, lower-latin) ') ';
}

ol.lst-kix_list_3-5 {
  list-style-type: none;
}

ul.lst-kix_list_5-4 {
  list-style-type: none;
}

ol.lst-kix_list_3-6 {
  list-style-type: none;
}

ul.lst-kix_list_5-1 {
  list-style-type: none;
}

.lst-kix_list_8-0 > li:before {
  content: '' counter(lst-ctn-kix_list_8-0, lower-latin) ') ';
}

ol.lst-kix_list_3-7 {
  list-style-type: none;
}

ul.lst-kix_list_5-2 {
  list-style-type: none;
}

ol.lst-kix_list_3-8 {
  list-style-type: none;
}

.lst-kix_list_8-7 > li:before {
  content: '' counter(lst-ctn-kix_list_8-7, lower-roman) '. ';
}

.lst-kix_list_3-8 > li:before {
  content: '' counter(lst-ctn-kix_list_3-8, decimal) '. ';
}

.lst-kix_list_8-5 > li:before {
  content: '(' counter(lst-ctn-kix_list_8-5, decimal) ') ';
}

.lst-kix_list_8-6 > li:before {
  content: '' counter(lst-ctn-kix_list_8-6, lower-latin) '. ';
}

.lst-kix_list_8-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_8-3, lower-latin) ') ';
}

ul.lst-kix_list_13-5 {
  list-style-type: none;
}

ul.lst-kix_list_13-4 {
  list-style-type: none;
}

ul.lst-kix_list_13-3 {
  list-style-type: none;
}

.lst-kix_list_3-6 > li:before {
  content: '' counter(lst-ctn-kix_list_3-6, lower-latin) '. ';
}

ul.lst-kix_list_13-2 {
  list-style-type: none;
}

ul.lst-kix_list_13-1 {
  list-style-type: none;
}

.lst-kix_list_3-7 > li:before {
  content: '' counter(lst-ctn-kix_list_3-7, lower-roman) '. ';
}

ul.lst-kix_list_13-0 {
  list-style-type: none;
}

.lst-kix_list_8-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_8-4, lower-roman) ') ';
}

.lst-kix_list_11-2 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_8-5.start {
  counter-reset: lst-ctn-kix_list_8-5 0;
}

ul.lst-kix_list_13-8 {
  list-style-type: none;
}

.lst-kix_list_11-1 > li:before {
  content: '\0025c6  ';
}

ul.lst-kix_list_13-7 {
  list-style-type: none;
}

ul.lst-kix_list_13-6 {
  list-style-type: none;
}

ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}

.lst-kix_list_11-0 > li:before {
  content: '\002794  ';
}

.lst-kix_list_8-8 > li:before {
  content: '' counter(lst-ctn-kix_list_8-8, decimal) '. ';
}

ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0;
}

.lst-kix_list_4-4 > li {
  counter-increment: lst-ctn-kix_list_4-4;
}

.lst-kix_list_4-8 > li:before {
  content: '' counter(lst-ctn-kix_list_4-8, decimal) '. ';
}

ol.lst-kix_list_6-4.start {
  counter-reset: lst-ctn-kix_list_6-4 0;
}

.lst-kix_list_4-7 > li:before {
  content: '' counter(lst-ctn-kix_list_4-7, lower-roman) '. ';
}

ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}

.lst-kix_list_7-3 > li {
  counter-increment: lst-ctn-kix_list_7-3;
}

ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}

.lst-kix_list_8-4 > li {
  counter-increment: lst-ctn-kix_list_8-4;
}

ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}

ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0;
}

ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0;
}

ul.lst-kix_list_12-6 {
  list-style-type: none;
}

ol.lst-kix_list_8-6.start {
  counter-reset: lst-ctn-kix_list_8-6 0;
}

ul.lst-kix_list_12-5 {
  list-style-type: none;
}

ul.lst-kix_list_12-4 {
  list-style-type: none;
}

.lst-kix_list_3-3 > li {
  counter-increment: lst-ctn-kix_list_3-3;
}

ul.lst-kix_list_12-3 {
  list-style-type: none;
}

ul.lst-kix_list_12-2 {
  list-style-type: none;
}

ol.lst-kix_list_6-3.start {
  counter-reset: lst-ctn-kix_list_6-3 0;
}

ul.lst-kix_list_12-1 {
  list-style-type: none;
}

ul.lst-kix_list_12-0 {
  list-style-type: none;
}

ul.lst-kix_list_12-8 {
  list-style-type: none;
}

ul.lst-kix_list_12-7 {
  list-style-type: none;
}

ol.lst-kix_list_8-0.start {
  counter-reset: lst-ctn-kix_list_8-0 0;
}

.lst-kix_list_7-0 > li:before {
  content: '' counter(lst-ctn-kix_list_7-0, decimal) ') ';
}

ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}

.lst-kix_list_6-2 > li {
  counter-increment: lst-ctn-kix_list_6-2;
}

.lst-kix_list_2-6 > li:before {
  content: '\0025cb  ';
}

.lst-kix_list_3-7 > li {
  counter-increment: lst-ctn-kix_list_3-7;
}

.lst-kix_list_2-4 > li:before {
  content: '\0025c6  ';
}

.lst-kix_list_2-8 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_7-1 > li:before {
  content: '' counter(lst-ctn-kix_list_7-1, lower-latin) ') ';
}

.lst-kix_list_7-5 > li:before {
  content: '(' counter(lst-ctn-kix_list_7-5, lower-roman) ') ';
}

.lst-kix_list_6-6 > li {
  counter-increment: lst-ctn-kix_list_6-6;
}

.lst-kix_list_7-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_7-3, decimal) ') ';
}

.lst-kix_list_10-0 > li:before {
  content: '\002794  ';
}

.lst-kix_list_13-6 > li:before {
  content: '-  ';
}

.lst-kix_list_13-8 > li:before {
  content: '-  ';
}

.lst-kix_list_6-7 > li {
  counter-increment: lst-ctn-kix_list_6-7;
}

.lst-kix_list_8-7 > li {
  counter-increment: lst-ctn-kix_list_8-7;
}

ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}

.lst-kix_list_7-7 > li:before {
  content: '' counter(lst-ctn-kix_list_7-7, lower-latin) '. ';
}

ol.lst-kix_list_8-1.start {
  counter-reset: lst-ctn-kix_list_8-1 0;
}

.lst-kix_list_7-5 > li {
  counter-increment: lst-ctn-kix_list_7-5;
}

.lst-kix_list_10-4 > li:before {
  content: '\0025c6  ';
}

.lst-kix_list_10-8 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_4-0 > li:before {
  content: '' counter(lst-ctn-kix_list_4-0, lower-latin) ') ';
}

.lst-kix_list_3-8 > li {
  counter-increment: lst-ctn-kix_list_3-8;
}

ol.lst-kix_list_6-8.start {
  counter-reset: lst-ctn-kix_list_6-8 0;
}

.lst-kix_list_10-2 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_4-6 > li {
  counter-increment: lst-ctn-kix_list_4-6;
}

.lst-kix_list_4-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_4-4, lower-roman) ') ';
}

ol.lst-kix_list_6-5.start {
  counter-reset: lst-ctn-kix_list_6-5 0;
}

.lst-kix_list_4-2 > li:before {
  content: '' counter(lst-ctn-kix_list_4-2, decimal) ') ';
}

.lst-kix_list_4-6 > li:before {
  content: '' counter(lst-ctn-kix_list_4-6, lower-latin) '. ';
}

.lst-kix_list_9-3 > li:before {
  content: '-  ';
}

ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 0;
}

.lst-kix_list_10-6 > li:before {
  content: '\0025cb  ';
}

.lst-kix_list_9-1 > li:before {
  content: '-  ';
}

ol.lst-kix_list_4-0 {
  list-style-type: none;
}

ol.lst-kix_list_4-1 {
  list-style-type: none;
}

ol.lst-kix_list_4-2 {
  list-style-type: none;
}

ol.lst-kix_list_4-3 {
  list-style-type: none;
}

.lst-kix_list_9-7 > li:before {
  content: '-  ';
}

ol.lst-kix_list_6-7.start {
  counter-reset: lst-ctn-kix_list_6-7 0;
}

ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}

.lst-kix_list_11-4 > li:before {
  content: '\0025c6  ';
}

.lst-kix_list_12-4 > li:before {
  content: '\0025c6  ';
}

.lst-kix_list_9-5 > li:before {
  content: '-  ';
}

.lst-kix_list_12-2 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_4-8 {
  list-style-type: none;
}

.lst-kix_list_7-4 > li {
  counter-increment: lst-ctn-kix_list_7-4;
}

.lst-kix_list_11-6 > li:before {
  content: '\0025cb  ';
}

.lst-kix_list_1-0 > li:before {
  content: '\002794  ';
}

ol.lst-kix_list_4-4 {
  list-style-type: none;
}

.lst-kix_list_11-8 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_4-5 {
  list-style-type: none;
}

.lst-kix_list_1-2 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_4-6 {
  list-style-type: none;
}

.lst-kix_list_12-0 > li:before {
  content: '\002794  ';
}

ol.lst-kix_list_4-7 {
  list-style-type: none;
}

ol.lst-kix_list_8-4.start {
  counter-reset: lst-ctn-kix_list_8-4 0;
}

.lst-kix_list_1-4 > li:before {
  content: '\0025c6  ';
}

.lst-kix_list_13-0 > li:before {
  content: '-  ';
}

ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}

.lst-kix_list_8-8 > li {
  counter-increment: lst-ctn-kix_list_8-8;
}

.lst-kix_list_13-4 > li:before {
  content: '-  ';
}

.lst-kix_list_1-6 > li:before {
  content: '\0025cb  ';
}

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

.lst-kix_list_2-0 > li:before {
  content: '\002794  ';
}

ol.lst-kix_list_8-3.start {
  counter-reset: lst-ctn-kix_list_8-3 0;
}

.lst-kix_list_12-6 > li:before {
  content: '\0025cb  ';
}

.lst-kix_list_4-5 > li {
  counter-increment: lst-ctn-kix_list_4-5;
}

.lst-kix_list_1-8 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_2-2 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_13-2 > li:before {
  content: '-  ';
}

.lst-kix_list_12-8 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_8-2 > li {
  counter-increment: lst-ctn-kix_list_8-2;
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c14 {
  margin-left: 72pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 0.9958333333333332;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Roboto';
  font-style: normal;
}

.c3 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  /* font-family: "Arial"; */
  font-style: normal;
}

.c6 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Roboto';
  font-style: italic;
}

.c29 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 0.9958333333333332;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c32 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 0.9958333333333332;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c2 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9958333333333332;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c48 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c19 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 0.9958333333333332;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c12 {
  padding-top: 12pt;
  padding-bottom: 0pt;
  line-height: 0.9958333333333332;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c26 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.0666666666666667;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c0 {
  text-decoration-skip-ink: none;
  font-family: 'Roboto';
  -webkit-text-decoration-skip: none;
  font-weight: 700;
  text-decoration: underline;
}

.c17 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}

.c24 {
  -webkit-text-decoration-skip: none;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  /* font-family: "Arial" */
}

.c27 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-style: normal;
}

.c4 {
  color: #000000;
  vertical-align: baseline;
  font-size: 16pt;
  font-style: normal;
}

.c31 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
}

.c22 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 12pt;
}

.c11 {
  color: #000000;
  vertical-align: baseline;
  font-size: 13pt;
  font-style: normal;
}

.c46 {
  color: #ff0000;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}

.c28 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}

.c37 {
  color: #000000;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}

.c8 {
  font-size: 15pt;
  font-family: 'Roboto';
  font-weight: 700;
}

.c25 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
}

.c44 {
  background-color: #ffffff;
  max-width: 510.3pt;
  padding: 31.5pt 45pt 35.5pt 56.7pt;
}

.c33 {
  font-size: 16pt;
  font-weight: 700;
}

.c23 {
  padding: 0;
  margin: 0;
}

.c10 {
  margin-left: 36pt;
  padding-left: 0pt;
}

.c18 {
  color: inherit;
  text-decoration: inherit;
}

.c7 {
  font-weight: 400;
  font-family: 'Arimo';
}

.c36 {
  margin-left: 72pt;
  padding-left: 0pt;
}

.c13 {
  font-weight: 700;
  font-family: 'Roboto';
}

.c35 {
  font-weight: 700;
  /* font-family: "Arial" */
}

.c9 {
  font-weight: 400;
  font-family: 'Roboto';
}

.c34 {
  text-decoration: none;
}

.c40 {
  font-size: 12pt;
}

.c16 {
  margin-left: 36pt;
}

.c43 {
  font-family: 'Roboto';
}

.c39 {
  font-size: 16pt;
}

.c47 {
  margin-left: 72pt;
}

.c15 {
  color: #0000ff;
}

.c41 {
  font-size: 13pt;
}

.c20 {
  font-style: italic;
}

.c38 {
  font-size: 10pt;
}

.c5 {
  height: 11pt;
}

.c21 {
  font-size: 14pt;
}

.c45 {
  font-weight: 700;
}

.c42 {
  font-size: 18pt;
}

.c30 {
  page-break-after: avoid;
}

.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  /* font-family: "Arial"; */
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  /* font-family: "Arial"; */
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}

h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  /* font-family: "Arial"; */
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  /* font-family: "Arial"; */
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  /* font-family: "Arial"; */
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  /* font-family: "Arial"; */
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  /* font-family: "Arial"; */
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  /* font-family: "Arial"; */
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
