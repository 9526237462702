@font-face {
  font-family: 'myHelvitikaFont';
  src: url('../../fonts/HELVETICANEUELTPRO-ROMAN.OTF');
}

@mixin text_design($fontSize, $Color, $letterSpacing) {
  font-size: $fontSize; // 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: $letterSpacing; //normal;
  color: $Color; // #f57a08;
}

.campaign-modal-view {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.addCustView {
  display: grid;
  grid-template-rows: 50px auto 75px;
  height: 280px;
}

.modal_view {
  grid-template-rows: 40px 200px 50px;
  height: 290px;
  .advert {
    display: flex;
    align-items: center;
    .text {
      margin-left: 3%;
      @include text_design(0.8em, #000000, normal);
    }
  }

  .brand_modal {
    .info {
      padding: 1% 0;
      display: flex;
      flex-direction: column;
      margin: 8px 18px;
      h5 {
        @include text_design(0.82em, #707070, 0.4px);
        opacity: 0.89;
        padding-top: 0.3%;
      }
    }

    .brands {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2.5%;

      .brand_view {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        border-left: 3px solid #0081ed;
        width: 100%;
        height: auto;
        padding: 10px 20px;

        .brand_heading {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          justify-content: space-between;

          .text {
            width: 100%;
            @include text_design(0.9em, #000000, 0.4px);
          }

          .brand_done {
            width: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: auto 0;
            img {
              width: 10px;
              margin-left: 7%;
              margin-bottom: auto;
            }
            @include text_design(0.73em, #67c453, normal);
          }
        }

        .select_brand {
          padding: 2% 0% 2%;
          width: 70%;
        }
      }
    }
  }
}
