.billingAnalytics {
  width: 100%;
  /*height:100%*/

  .mainInfoCard {
    position: relative;
    width: 96.5%;
    margin: 0 auto;
    margin-top: 2%;
    height: 72%;
    padding: 2em 0em !important;
  }

  .dateInfo {
    width: 100%;
    margin-bottom: 2% !important;
  }

  .dayInfo {
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2.4px;
    color: #43424b;
  }

  .selectedDate {
    opacity: 0.4;
    font-family: Helvetica;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #43424b;
    margin-top: 6px;
  }

  .date {
    display: inline-block;
    width: 33.33%;
  }

  .dateManual {
    text-align: center;
  }

  .dateToday {
    text-align: right !important;
    vertical-align: top;
    padding-right: 3%;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2.4px;
    color: #43424b;
  }

  .dateToday:hover {
    cursor: pointer;
  }

  .dateYesterday {
    text-align: left !important;
    vertical-align: top;
    padding-left: 3%;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2.4px;
    color: #43424b;
  }

  .dateYesterday:hover {
    cursor: pointer;
  }

  .BillingInfoCardLine {
    height: 100%;
  }

  .BillingInfoCardLine:nth-child(4) {
    padding-bottom: 1.5%;
  }

  .infoCard {
    width: 26.3%;
    height: 50% !important;
    display: inline-block;
    margin-left: 6.1%;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    border: 1px solid hsl(0, 0%, 95%);
    box-shadow: 0 4px 13px 0px hsl(0, 0%, 95%) !important;
    cursor: pointer;
    position: relative;
    top: 0px;
    transition: all 200ms;

    &:hover {
      box-shadow: 0 4px 18px 0px hsl(0, 0%, 92%) !important;
      top: 2px;
    }
  }

  .transactionCards {
    padding: 0 50px;
  }

  .transactionCard {
    width: 23%;
    margin-left: 2%;
  }

  .transCard {
    width: 31%;
    margin-left: 2%;
  }

  .cardContainer {
    display: grid;
    grid-template-columns: 25% 75%;

    padding-top: 5%;
  }

  .infoCardImage {
    width: 65%;
  }

  .cardNameInfo span {
    display: block !important;
  }

  .cardName1 {
    font-size: 0.8em;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-top: 2%;
  }

  .cardName2 {
    font-size: 1.4em;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: #43424b;
    margin-top: 2%;
  }

  .BillingInfoCardLine:nth-child(2) > .infoCard:nth-child(1) {
    color: #0b86f3 !important;
  }

  .BillingInfoCardLine:nth-child(2) > .infoCard:nth-child(2) {
    color: #7d10ef !important;
  }

  .BillingInfoCardLine:nth-child(2) > .infoCard:nth-child(3) {
    color: #1a48e8 !important;
  }

  .BillingInfoCardLine:nth-child(4) > .infoCard:nth-child(1) {
    color: #ae0c5d !important;
  }

  .BillingInfoCardLine:nth-child(4) > .infoCard:nth-child(2) {
    color: #f53d08 !important;
  }

  .BillingInfoCardLine:nth-child(4) > .infoCard:nth-child(3) {
    color: #17df09 !important;
  }

  /*circle*/
  .billingInfoCardCircleLine {
    height: 120px;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .billingInfoCardCircle {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin: 0% auto;
  }

  .billingInfoCardCircle:nth-child(1) {
    border: 1px solid #f84342;
    margin-right: 5%;
  }

  .billingInfoCardCircle:nth-child(2) {
    border: 1px solid #d908f0;
    margin-left: 5%;
  }

  .billingInfoCardCircle:nth-child(1) > .circleCardName1 {
    color: #f57c08;
  }

  .billingInfoCardCircle:nth-child(2) > .circleCardName1 {
    color: #d908f0;
  }

  .infoCardCircleImage {
    width: 35px;
    height: 35px;
  }

  .circleCardName1 {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0b86f3;
    text-align: center;
  }

  .circleCardName2 {
    font-size: 18px;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.6px;
    text-align: center;
    color: #43424b;
  }

  /*--------Date picker----------*/
  .datePickerButton {
    height: 30px;
    border: 1px solid hsla(217, 100%, 62%, 1);
    border-radius: 4px;
    // background-image: linear-gradient(259deg, #1ca7ea, #0b59df);
    background-color: #fff;
    font-size: 70%;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: hsla(217, 100%, 62%, 1);
    width: 20%;
    margin-left: 40%;
    outline: none;
  }

  .react-datepicker-wrapper {
    width: 100% !important;
  }

  .react-datepicker__input-container {
    margin-top: 1.9%;
    margin-right: 1.7%;
    width: 100% !important;
  }

  .billingDatePicker {
    width: 100%;
  }

  .react-datepicker-popper {
    width: 100% !important;
  }

  .billingCalender {
    margin-left: 33% !important;
  }

  /*-----popups-------*/
  .billAnalyticsPopup-overlay {
    .close {
      width: 50px;
      cursor: pointer;
      position: absolute;
      display: block;
      right: -25px;
      top: -25px;
      font-size: 24px;
      opacity: 1 !important;
      transition: 200ms;
    }

    .popup-content {
      width: 80% !important;
    }
  }

  .noData {
    text-align: center;
  }
}
