.rechargeWrapper {
  width: 100%;

  .headButtonsGroup {
    position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: 33.33% 33.33% 33.33%;
    margin: 0% auto;
    margin-left: 1.9%;
    margin-top: 2%;
  }

  .headbutton {
    width: 90%;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    display: grid;
    grid-template-columns: 30% 70%;
    cursor: pointer;
  }
  .headButtonGreen {
    background-color: #0fe8a6;
    color: #ffffff;
    cursor: pointer;
  }

  .headButtonBlue {
    background-color: #2591ff;
    color: #ffffff;
    cursor: pointer;
  }

  .headButtonYellow {
    background-color: #ffa407;
    color: #ffffff;
    cursor: pointer;
  }

  .headButtonNormal {
    background-color: #ffffff;
    color: black;
  }

  .headButtonImage {
    margin: 0% auto;
  }
  .headButtonImage img {
    margin-top: 40%;
    margin-bottom: 40%;
    width: 80%;
  }

  .headButtonInfo {
    font-size: 130%;
    font-weight: 420;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 71px;
  }

  /*-------recharge section-----*/
  .rechargeSection {
    width: 43%;
    margin: 0% auto;
    margin-top: 2%;
    background: #fff;

    label {
      margin-left: 4px;
      font-size: 0.9em;
      color: #495057;
    }
  }
  .rechargeSectionHeading {
    font-size: 100%;
    font-weight: 420;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.8px;
    text-align: left;
    color: #43424b;
    margin-top: 2%;
  }
  .selectCompany {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .rechargeSquares {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 5% 0% 5% 0%;
  }
  .rechargeSquareButton {
    background: transparent;
    border: 2px solid #2591ff;
    border-radius: 5px;
    width: 90%;
    margin-left: 5%;
    font-size: 0.9em;
    padding: 2%;
    font-weight: 500;
    color: #2591ff;
  }
  .selectedSquare {
    background: #2591ff;
    color: white;
    outline: none;
  }

  .balanceCircles {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .balanceCircle {
    position: relative;
  }
  .balanceCircles img {
    z-index: 0;
    width: 100% !important;
  }
  .circleAmount {
    position: absolute;
    width: 80%;
    margin-top: 35%;
    z-index: 1;
    font-size: 100%;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-left: 20%;
  }
  .circleAmountblack {
    color: black !important;
  }
  .rechargeButton {
    width: 100%;
    margin-top: 3%;
    margin-left: 0;
  }
  .close {
    width: 10%;
    cursor: pointer;
    position: absolute;
    display: block;
    right: -5%;
    top: -6%;
    font-size: 24px;
    opacity: 1 !important;
  }

  //payment popup css
  .popup-content {
    width: 30% !important;
  }
  .paymentPopupGST {
    width: 90%;
    margin: 0% auto;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .gstBreakdown {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center;
    padding: 1%;
  }
  hr.gstHr {
    border: 1px solid #1ca7ea;
    margin: 0% auto;
  }
  .proceedPaymentButton {
    width: 60%;
    border-radius: 4%;
    margin-left: 20%;
    padding: 2%;
    margin-top: 5%;
    border: none;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1);
    background-image: linear-gradient(95deg, #1ca7ea, #0b59df);
    font-size: 90%;
    font-weight: 425;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
}
