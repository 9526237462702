h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0px;
}

@font-face {
  font-family: myHelvitikaFont;
  src: url('../../fonts/HelveticaNeueLTPro-Md.woff2');
}

@mixin text-design($fontSize, $fontColor, $letterSpacing, $opacityVal) {
  opacity: $opacityVal;
  font-size: $fontSize;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: $letterSpacing;
  text-align: left;
  color: $fontColor;
}

.liveBox {
  margin-left: 12%;
  margin-right: 12%;
  margin-top: 5%;
  margin-bottom: 5%;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
  border: solid 0.1px rgba(112, 112, 112, 0.25);
  background-color: #ffffff;
  height: auto;
  border-radius: 8px;

  .greenDot {
    color: #10ff00;
    margin-right: 2%;
  }

  .liveboxHeading {
    display: flex;
    flex-direction: row;
    margin: 10px auto 0;
    width: 300px;
    position: relative;
    justify-content: flex-start;
    .liveInfo {
      width: 65%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include text-design(0.8em, #4d4f5c, 0.8px, 1);
    }

    .deactiveButtonBox {
      display: flex;
      justify-content: flex-end;
      width: 35%;
      .deactivateButton {
        border-radius: 8px;
        border: none;
        color: white;
        padding: 8px 8px 8px;
        @include text-design(12px, #ffffff, 0.8px, 1);
        background-color: #d91414;
      }
    }
  }

  .liveCampaignImageBox {
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin: 5% 0;
    .leftBtn,
    .rightBtn {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
      .left,
      .right {
        width: 40px;
        height: 40px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #707070;
        background-color: #ffffff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .leftIcon,
        .rightIcon {
          width: 25px;
        }
      }
    }

    .advertiseCarousel {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 300px;
      height: 300px;
    }
    .noCampaigns {
      border-radius: 5px;
      background: #dfdfe8;
      height: 100%;
      border: 1px solid #bbb7b7;
      width: 100%;
      display: flex;
    }
    .noCampaignsLabel {
      @include text-design(1em, #000000, 1.4px, 0.6);
      text-align: center;
      margin: auto;
    }
  }

  .campaignName {
    @include text-design(0.8em, #000000, 0.4px, 1);
    text-align: center;
    margin: 0px 0 5px;
  }
  .filter-auto-complete {
    width: 115%;
    top: 57px;
    left: -15px;
    border: none;
  }

  .brand {
    width: 100%;
    display: flex;
    justify-content: center;
    .brand_select {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 70%;
      padding: 0.5%;
      .dropDownHeading {
        @include text-design(0.8em, #000000, 0.8px, 1);
        width: 35%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }

      .brandDropdown {
        width: 70%;
        display: flex;
        justify-content: flex-end;
        select {
          background-color: white;
          border: none;
          border-radius: 4px;
          display: inline-block;
          line-height: 1.5em;
          padding: 0;
          @include text-design(0.8em, #000000, 0.4px, 1);

          margin: 0;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          -webkit-appearance: none;
          -moz-appearance: none;
        }

        select.form-control {
          background-image: linear-gradient(45deg, transparent 50%, #0069ff 50%),
            linear-gradient(135deg, #0069ff 50%, transparent 50%),
            linear-gradient(to right, white, white);
          background-position: calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px), 100% 0;
          background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
          background-repeat: no-repeat;
        }

        .form-control:focus {
          box-shadow: none;
        }
      }
    }
  }

  .storeSelect {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3% 5%;
    border-top: 0.02px solid lightgray;
    .heading {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include text-design(0.8em, #000000, 0.8px, 1);
    }

    .store_select {
      margin-left: 1%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 75%;

      .css-2b097c-container {
        width: 100%;
      }

      div {
        .css-1okebmr-indicatorSeparator {
          display: none;
        }

        .css-1wa3eu0-placeholder {
          @include text-design(0.58em, #797979, 0.8px, 0.8);
        }

        .selector_main {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          overflow: hidden;
          padding: 5px 10px;
          .selector_icon {
            width: 25px;
            height: 25px;
            .logo {
              width: inherit;
              height: inherit;
              border: solid 1px #007bf5;
              border-radius: 50px;
            }
          }

          .selector_option {
            width: 100%;
            margin: 0 10px;
            @include text-design(0.7em, #000000, 1.4px, 1);
          }

          .active {
            width: 50px;
            margin: auto;
            .active_ads {
              width: inherit;
              text-align: center;
              border-radius: 35px;
              align-items: center;
              @include text-design(0.6em, #ffffff, 0.8px, 1);
              padding: 5px 7px;
            }
          }
        }
      }
    }
  }
}
