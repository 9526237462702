.transactions {
  text-align: center;
  padding-top: 10%;
  .transactionStatusText {
    font-size: 125%;
    font-weight: 425;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
  }
  .transactionStatusIcon {
    font-size: 200%;
  }
  .transPending {
    color: orange;
  }
  .transSuccess {
    color: green;
  }
  .transFail {
    color: red;
  }
  .transactionsGoBackButton {
    width: 20%;
    border: none;
    border-radius: 6px;
    background-image: linear-gradient(259deg, #1ca7ea, #0b59df);
    font-size: 100%;
    font-weight: 425;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0.5%;
    margin-top: 2%;
  }
}
