h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0px;
}

@font-face {
  font-family: 'myHelvitikaFont';
  src: url('../../fonts/HelveticaNeueLTPro-Md.woff2');
}

@mixin text-design($fontSize, $fontColor, $letterSpacing, $opacityVal) {
  opacity: $opacityVal;
  font-size: $fontSize;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: $letterSpacing;
  text-align: left;
  color: $fontColor;
}

html * {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

body {
  overscroll-behavior-y: none;
}

//Header Bar
.manage-stores-header {
  width: auto;
  background: #fff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    @include text-design(1em, #0089f9, 1.4px, 1);
    font-weight: 400;
    margin: auto 10px auto 20px;
  }

  .breadcrumb {
    background: #fff;
    margin-bottom: 0;
    padding: 1rem;
    font-size: 0.7em;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    color: #0089f9;
    content: '-';
  }
}

/* Store Select CSS */
.store-select-option,
.store-select-value {
  font-size: inherit;
  cursor: pointer;
  background: transparent;
  width: 100%;
  padding: 8px 12px;
  display: block;
  color: inherit;
  user-select: none;
  text-transform: capitalize;
  -webkit-tap-highlight-color: 'rgba(0, 0, 0, 0)';
  &:focus {
    background: #deebff;
  }
  &:hover {
    background: #d6ebff;
  }
  &:disabled {
    color: hsl(0, 0%, 80%);
  }

  .store-select-img {
    margin-right: 10px;
    width: 23px;
    height: 23px;
    border: 1px solid #707070;
    border-radius: 50%;
  }
}
.store-select-value {
  padding: 5px 4px;
}

/* Store Select CSS */

//Search Input

.input-group {
  z-index: 11;
  border-radius: 0.25rem;
  border: 1px solid #707070;

  .input-group-prepend {
    border-right: 0;
    background: #ffffff;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  }
  .input-group-text {
    border: 0;
    background-color: #fff;
    font-weight: 900;
    padding: 0;
    width: 40px;
    background: url('../../images/search.svg') no-repeat 10px 11px;
    background-size: 17px;
  }
  input {
    border-left: 0;
    border: 0;
    padding: 0.475rem 0.75rem 0.475rem 0em;
    height: 40px;
    font-size: 12px;

    &::placeholder {
      font-size: 12px;
    }
  }
  .form-control:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
}
.auto-complete {
  z-index: 11;
  position: relative;
  .filter-auto-complete {
    // border: 1px solid #797979;
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    background: #fff;
    z-index: 10;
    top: 40px;
    width: 100%;
    overflow-y: auto;
    max-height: 250px;
    // -webkit-box-shadow: 0px 0px 3px 1px rgba(194,194,194,1);
    // -moz-box-shadow: 0px 0px 3px 1px rgba(194,194,194,1);
    // box-shadow: 0px 0px 3px 1px rgba(194,194,194,1);
  }
  .filter-auto-complete-item {
    display: inline-flex;
    cursor: pointer;
    width: 100%;
    padding: 13px 19px;
    border-bottom: 1px solid #e2e2e2;
    &:focus {
      background: #deebff;
    }
    &:hover {
      background: #d6ebff;
    }
    &:disabled {
      color: hsl(0, 0%, 80%);
    }
  }
  .filter-auto-complete-image {
    width: 25px;
    height: 25px;

    img {
      width: inherit;
      height: inherit;
      border: 1px solid #0089f9;
      border-radius: 50%;
    }
  }
  .filter-auto-complete-label {
    margin-left: 20px;
    font-size: 0.9em;
    margin-top: auto;
    margin-bottom: auto;
  }
}
input[type='radio'].bm-radio {
  opacity: 0;
  position: absolute;
  + .bm-radio-label {
    margin: 0;
    &:before {
      content: '';
      background: #fff;
      border-radius: 100%;
      border: 1px solid darken(#fff, 25%);
      display: block;
      width: 20px;
      height: 20px;
      position: relative;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
    }
  }
  &:checked {
    + .bm-radio-label {
      &:before {
        background-color: #25a6ea;
        box-shadow: inset 0 0 0 4px #fff;
      }
    }
  }
  &:focus {
    + .bm-radio-label {
      &:before {
        outline: none;
        border-color: #25a6ea;
      }
    }
  }
  &:disabled {
    + .bm-radio-label {
      &:before {
        box-shadow: inset 0 0 0 4px #fff;
        border-color: #707070;
        background: #707070;
      }
    }
  }
  + .bm-radio-label {
    &:empty {
      &:before {
        margin-right: 0;
      }
    }
  }
}
.sort-icons {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: auto 8px;
  height: 13px;
  width: 9px;
  .bm-triangle {
    width: 9px;
    height: 6px;
    left: 0;
    position: absolute;
    cursor: pointer;
  }
  .bm-triangle:first-child {
    top: 0;
  }
  .bm-triangle:last-child {
    top: 7px;
    transform: rotate(180deg);
  }
}
.bm-dropdown {
  position: relative;
  margin: 0 0px 0 10px;
  color: #0084e9;
  .bm-dropdown-selected {
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
    display: flex;
    .bm-dropdown-value {
      margin-right: 7px;
    }
    .bm-dropdown-indicator {
      padding-top: 2px;
    }
  }
  .bm-dropdown-options {
    position: absolute;
    top: 150%;
    transform: translateX(-30%);
    background: #ffffff;
    padding: 10px 0 15px;
    z-index: 100;
    border: 0.25px solid #cccccc;
  }
  .bm-dropdown-option {
    min-width: 125px;
    color: #52565f;
    padding: 7px 25px;
    text-align: center;
    cursor: pointer;
    font-size: 0.8em;
    white-space: nowrap;

    &.selected {
      color: inherit;
      font-size: 1em;
    }
  }
}
