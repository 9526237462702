/* scrollbars*/
/* width */
.feedbackPreviewCard::-webkit-scrollbar {
  box-shadow: inset 0 0 1px grey;
  width: 2px;
}

/* Track */
.feedbackPreviewCard::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
.feedbackPreviewCard::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
.feedbackPreviewCard::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(to bottom, #1ca7ea, #0b59df);
}

/* scrollbars*/
/* width */
#feedbackCardsScroll::-webkit-scrollbar {
  box-shadow: inset 0 0 1px grey;
  width: 2px;
}

/* Track */
#feedbackCardsScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
#feedbackCardsScroll::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
#feedbackCardsScroll::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(to bottom, #1ca7ea, #0b59df);
}

.auto-complete,
.pagination {
  width: 55%;
}

.feedback-pagination {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;

  li {
    text-align: center;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s;

    &:hover {
      background-color: hsl(0, 0%, 95%);
    }

    a {
      color: hsla(217, 100%, 65%, 1);
      text-decoration: none;
    }
  }

  li.pagination_activePage {
    background-color: hsla(217, 100%, 95%, 1);
    border: 1px solid white;

    a {
      color: hsla(217, 63%, 51%, 1);
    }
  }
}

.filter-btn:hover svg {
  fill: #ffffff;
}

.feedback-brand-info {
  width: 100%;

  .feedback-badge {
    width: max-content;
    margin-left: auto;
  }
}

.aePagination {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0 1rem;
  list-style: none;

  li {
    cursor: pointer;
    border-radius: 2px;
    transition: all 0.3s;

    &.previous,
    &.next {
      background-color: hsl(0, 0%, 95%);
      margin: 0 4px;
    }

    &:hover {
      background-color: hsl(0, 0%, 95%);
    }

    a {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #33475b;
      text-decoration: none;
      font-size: 12px;
      &:focus {
        outline: none;
      }
    }
  }

  li.pagination_activePage {
    background-color: #b0cefc;
  }
}
