@font-face {
  font-family: 'myHelvitikaFont';
  src: url('../../fonts/HELVETICANEUELTPRO-ROMAN.OTF');
}

@mixin text_design($fontSize, $Color, $letterSpacing) {
  font-size: $fontSize; // 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: $letterSpacing; //normal;
  color: $Color; // #f57a08;
}

@mixin selector_design($width) {
  width: $width;
  height: 40px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-top: 10;

  .logo {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    //margin-top: 5px;
    margin: 5px;
  }

  .selector_name {
    height: 100%;
    text-align: left;
    justify-content: space-between;
    margin-top: 12px;
    margin-left: 5px;
    @include text_design(12px, #000000, normal);
  }

  .balance {
    margin-top: 8px;
    text-align: center;
    .bal {
      margin-left: 2px;
      @include text_design(14px, #63c64c, normal);
    }
  }
}

.selector_main {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 0.8px solid rgba(0, 0, 0, 0.16);
  cursor: pointer;
  justify-items: center;
  .selector_icon {
    @include selector_design(10%);
  }

  .selector_option {
    justify-content: center;
    @include selector_design(65%);
  }

  .selector_balance {
    @include selector_design(25%);
  }
}

.selector_main:hover {
  background-color: lightskyblue;
}

.popup-content {
  padding: 0px !important;
  border-radius: 0px;
}

.popupContent {
  width: 100% !important;
}

.modal_view {
  display: grid;
  height: 380px;
  grid-template-rows: 40px 290px 50px;

  .modal_head {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    .backBtn {
      grid-column: 1/2;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.16);
      cursor: pointer;

      .back {
        width: 35%;
      }
    }

    .heading {
      grid-column: 2/16;
      .header_txt {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 20px;
        @include text_design(12px, #000000, normal);
      }
    }

    .confirm_txt {
      width: 200px;
      height: 100%;
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include text_design(14px, #000000, normal);
    }
  }

  .modal_body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    //border:1px solid red;

    .body {
      //border:1px solid green;
      width: 85%;
      height: 70%;
      border-radius: 15px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #0091e9;
      background-color: #ffffff;
      display: grid;
      grid-template-columns: repeat(7, 1fr);

      .icon {
        grid-column: 1/3;
        display: grid;
        align-items: flex-end;
        justify-items: center;
        .icon_img {
          width: 80px;
        }
        .icon_text {
          height: 80%;
          display: grid;
          align-items: flex-start;
          .text {
            @include text_design(12px, #008ef1, 0.8px);
          }
        }
      }

      .selector {
        grid-column: 3/8;
        width: 100%;
        display: grid;
        align-items: center;
        padding: 10px;
        .selector_txt {
          height: 100%;
          display: flex;
          padding-left: 10px;
          align-items: flex-end;
          .text {
            @include text_design(10px, #000000, 0.43px);
            line-height: 1.53px;
          }
        }

        .company_selector {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .css-2b097c-container {
            width: 100%;
            position: relative;
            top: 10px;

            .css-1okebmr-indicatorSeparator {
              display: none;
            }

            .css-6q0nyr-Svg {
              width: 25px;
              height: 25px;
            }
          }

          .show_bal_detail {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: flex-start;
            position: relative;
            top: 20px;
            .balance_detail {
              @include text_design(12px, #707070, normal);
              margin-top: 5px;
              margin-bottom: 0px;

              .amt {
                font-size: 15px;
                color: #63c64c;
              }
            }
          }
        }
      }
    }

    .response_msg {
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon_img {
        width: 8%;
      }

      .success_msg {
        margin-top: 15px;
        .msg {
          @include text_design(14px, #000000, normal);
          margin: 0;
          .msg_highlgt {
            color: #3b88fd;
          }
        }

        .msg--1 {
          margin-top: 5px;
        }
      }

      .error_msg {
        margin-top: 15px;
        .msg {
          @include text_design(14px, #000000, normal);
          margin: 0;
          .msg_highlgt {
            color: #3b88fd;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .msg--1 {
          margin-top: 5px;
        }
      }
    }
  }

  .amt_paid {
    position: relative;
    bottom: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .amt_heading {
      @include text_design(10px, #707070, normal);
      margin-bottom: 0px;
    }

    .amt {
      @include text_design(14px, #000, normal);
    }
  }

  .modal_footer {
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.09);
    background-color: #f5f4f4;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .footer_btn {
      padding: 10px;
      .cancelBtn {
        width: 85px;
        height: 30px;
        border-radius: 4px;
        // box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #797979;
        background-color: #ffffff;
        @include text_design(12px, #707070, normal);
      }

      .cancelBtn:hover {
        background-color: red;
        @include text_design(12px, #ffffff, normal);
      }
      .payBtn {
        width: 85px;
        height: 30px;
        border-radius: 4px;
        // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
        border: solid 1px #797979;
        background-color: #ffffff;
        margin-left: 5px;
        @include text_design(12px, #707070, normal);
      }

      .payBtn:hover {
        background-color: #63c64c;
        @include text_design(12px, #ffffff, normal);
      }
    }
  }
}
