.consumerAnalytics {
  height: 100% !important;
  .headTabGroup {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
  }

  .headTabChild {
    display: grid;
    grid-template-columns: 35% 65%;
    justify-items: center;
    align-items: center;
  }
  .tabImage img {
    width: 65px;
    height: 65px;
  }

  .tabText {
    justify-self: flex-start;
  }

  .tabTextLabel {
    font-size: 80%;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 6%;
  }
  .tabTextNumber {
    font-size: 175%;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
}

/*numbers Popup ConsumerAnalytics*/
.exportExcelbutton {
  position: absolute;
  border-radius: 10px;
  border: none;
  background-color: #2591ff;
  font-size: 90%;
  font-weight: 500;
  letter-spacing: 1px;
  color: #ffffff;
  float: right;
  bottom: 3%;
  padding: 10px 25px;
  outline: none;
  right: 3%;
  transition: 200ms;

  &:hover {
    background-color: rgb(55, 153, 252);
  }
}

.numbersPopupConsumerAnalytics {
  height: 60% !important;
  margin: 2% 3%;
  padding: 1em;

  /* width */
  &::-webkit-scrollbar {
    box-shadow: inset 0 0 1px grey;
    width: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-image: linear-gradient(to bottom, #1ca7ea, #0b59df);
  }

  & > div:first-child {
    height: 10%;
  }

  & > div:nth-child(2) {
    height: 90%;
  }

  .detailsTable tbody {
    display: block;
  }

  .infiniteScrollContainer {
    overflow: auto;
    height: auto;

    .endOfList {
      font-size: 12px;
      color: hsl(0, 0%, 50%);
      margin-top: 1em;
      text-align: center;
    }
  }

  .consumerDataHeading {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    text-align: center;
    font-size: 100%;
    font-weight: 400;
    letter-spacing: 2.4px;
    color: #43424b;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background-color: #ffffff !important;
    padding: 1% 0px;
    margin-bottom: 1%;
    border-bottom: 2px solid hsl(0, 0%, 95%);
  }
  .consumerDataInner {
    display: grid;
    font-size: 80%;
    grid-template-columns: 25% 25% 25% 25%;
    text-align: center;
    color: #43424b;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .lastVisitedColor {
    color: #1ca7ea;
  }
}
