.usage-content__cell {
  font-size: 14px;
  padding: 15px 30px;
  color: #33475b;
}
td:first-child .usage-content__cell {
  padding-left: 0px;
}
.usagePaginationOverride {
  padding: 0;
  margin-bottom: 0;
}

.usagePaginationContainer {
  margin-top: 2rem;
}
