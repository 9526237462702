.modal1 {
  padding: 20px 0px;
}

.chartsMain {
  min-height: 500px;

  .chartHeading {
    font-size: 100%;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2.4px;
    text-align: center;
    color: #43424b;
  }
}
