h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0px;
}

@font-face {
  font-family: 'myHelvitikaFont';
  src: url('../../fonts/HelveticaNeueLTPro-Md.woff2');
}

@mixin text-design($fontSize, $fontColor, $letterSpacing, $opacityVal) {
  opacity: $opacityVal;
  font-size: $fontSize;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: $letterSpacing;
  text-align: left;
  color: $fontColor;
}

.advertMain {
  width: 100%;
  margin-bottom: 0% !important;
  padding-bottom: 5% !important;
  background-color: #ffffff;

  .brandSelector {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 0.5%;
    align-items: center;
    justify-content: center;
    margin: 1.5% 0 0;
    .view {
      display: flex;
      flex-direction: row;
      width: 45%;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      padding: 0.8% 0%;
      border-radius: 4px;
      h4 {
        width: 25%;
        @include text-design(0.9em, #000000, 1.4px, 1);
      }
      .css-2b097c-container {
        width: 65%;

        .css-1wa3eu0-placeholder {
          @include text-design(0.6em, #707070, 1.4px, 1);
        }
      }
    }
  }

  .advertHeading {
    padding-top: 0.5%;
    padding-left: 1%;
    padding-right: 1%;
    display: flex;
    flex-direction: row;
    width: auto;
    margin: 1%;
    margin-bottom: 0;

    .selected_store {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: 50%;
      .stores,
      .advert_permission {
        @include text-design(14px, #000000, 1.4px, 1);
      }
    }
  }
  .advertHeading1 {
    font-size: 1.5em;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
  .advertHeading2 {
    opacity: 0.5;
  }

  .advertBoxes {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .previewBox {
    margin-left: 12%;
    margin-right: 12%;
    margin-top: 5%;
    margin-bottom: auto;
    box-shadow: 0 1.5px 6px 3px rgba(0, 0, 0, 0.14);
    border: solid 0.1px rgba(112, 112, 112, 0.25);
    background-color: #ffffff;
    height: auto;
    border-radius: 8px;
  }

  .previewBoxHeading {
    font-size: 100%;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #4d4f5c;
    margin-top: 10px;
  }

  .previewImage .imageInput {
    // max-width: 275px;
    // width: 80%;
    height: 260px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    cursor: pointer;
    margin-top: 10px;
  }
  .previewImage .croppedImage {
    margin-top: 10px;
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .previewBoxButtons {
    width: 250px;
    display: flex;
    justify-content: space-around;
    margin: 10px auto;
  }
  .previewButton {
    border-radius: 4px;
    background-color: #3b86ff;
    border: none;
    color: white;
    width: 80%;
    margin: 0% auto;
    padding: 2%;
    max-width: 130px;
    bottom: 2%;
  }
  .activateButton {
    border-radius: 4px;
    background-color: #1dd410;
    border: none;
    color: white;
    width: 80%;
    margin: 0% auto;
    max-width: 130px;
    padding: 2%;
  }

  .addAdvertCampaign {
    padding: 0 15%;
    .ad-input-group {
      width: 100%;
      display: flex;
      margin: 5px 0;
    }
    .ad-btn-group {
      width: 50%;
      display: flex;
      justify-content: center;
      margin: 5px auto;
    }
    .inputBox {
      height: 40px;
      display: block;
      border: none;
      margin: 5px 10px;
      border-radius: 0px;
      border: 1px solid black;
    }
    .campaignSubmitButton {
      border-radius: 6px;
      border: none;
      box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1);
      background-image: linear-gradient(103deg, #1ca7ea, #0b59df);
      text-align: center;
      color: #ffffff;
      font-size: 0.8em;
      font-weight: normal;
      height: 40px;
      max-width: 250px;
      margin: auto;
      padding: 10px 0.75em;
    }
  }

  //Advert List Start
  .advertList {
    width: 94%;
    margin: 1em auto 0;
    border-radius: 2px;
    border: solid 2px rgba(112, 112, 112, 0.18);
    background-color: #ffffff;
    overflow-x: hidden;

    table {
      width: 100%;
      margin-bottom: 5%;
    }

    tr {
      cursor: pointer;
    }
    td {
      text-align: center;
      padding: 7px 0;
    }
    tr:hover {
      border-radius: 2px;
      background-color: hsla(0, 0%, 44%, 0.18);
    }
    th {
      opacity: 0.5;
      font-size: 90%;
      font-weight: 450;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      padding: 7px 0;
      text-align: center;
    }
    .radioActive {
      display: grid;
      grid-template-columns: 55% 45%;
    }
    .advertRadioButton {
      font-size: 0.25em;
      vertical-align: bottom;
      width: 25%;
      margin-left: 75%;
    }
    .advertActiveButton {
      width: 60%;
      margin-left: 10%;
      font-size: 0.8em;
      opacity: 0.92;
      border-radius: 16px;
      border: none;
      background-color: #ffffff;
      color: black;
      padding-top: 2%;
      padding-bottom: 0 !important;
    }
    .activeCampaignRow {
      border-radius: 2px;
      border: solid 0.1px rgba(112, 112, 112, 0.18);
      background-color: rgba(0, 255, 85, 0.44);
    }
  }
  .advertListHeading {
    font-size: 1.2em;
    font-weight: 475;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    padding: 1% 1% 0% 1%;
    margin-top: 5px;
  }

  .redirectionUrl {
    max-width: 200px;
    align-items: center;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include text-design(0.8em, #0091e9, 0.4px, 1);
  }
  //Advert List Ends

  .liveCampaignImage {
    width: 225px;
    height: 225px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 3px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: grid;
  }

  .liveCampaignFooter {
    padding: 0.5% 0.5% 1%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    border-bottom: solid 1px #dfdede;
    margin: 5px 2% 0% 2%;
  }
  .liveCampaignName1 {
    opacity: 0.5;
    font-size: 1em;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .liveCampaignName {
    font-size: 1em;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    h5 {
      margin-left: 5px;
    }
  }
  .deactivateButton {
    opacity: 0.85;
    border-radius: 8px;
    border: none;
    color: white;
    padding: 5px 10px 5px;
    font-size: 14px;
    background-color: #f80303;
  }

  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 2px;
    position: relative;
    top: 2%;
    .auto-complete {
      width: 90%;
      margin-left: 0px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
    }

    .arrow {
      width: 10%;
      display: flex;
      justify-content: center;
      .arrowIcon {
        width: 18px;
      }
    }
  }

  //live campaign ends

  //crop image popup starts
  .crop-image {
    .popup-content {
      border-radius: 6px;
      width: 60% !important;
      height: auto !important;
      padding-bottom: 10px !important;
      .ReactCrop {
        max-width: 475px;
        max-height: 475px;
      }
      .heading {
        @include text-design(15px, #43424b, 1.4px, 1);
        font-weight: bold;
        text-align: center;
        padding: 10px 0;
      }
      .popupInner {
        height: 100%;
      }

      .crop-component {
        width: 80% !important;
        height: 75% !important;
        text-align: center;
        margin: 0% auto;
      }
    }
  }
  //crop image popup ends

  //viewCampaign popup starts
  .viewCampaign {
    .popupInner {
      height: 100%;
      padding: 10px;
    }
    .popup-content {
      border-radius: 6px;
      min-width: 300px;
      height: auto;
    }
    .viewCampaignHeading {
      text-align: center;
    }
    .adRedirectUrl {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 2%;
      @include text-design(0.8em, #000000, 0.4px, 1);
      h6 {
        height: auto;
        margin: 0px 5px;
        min-width: 110px;
        width: auto;
      }

      .middle-elem {
        cursor: pointer;
        color: #0091e9;
        word-break: break-all;
      }
    }
    .campaignBtn {
      margin: auto 0;
      cursor: pointer;
    }
    .input-group {
      width: 50%;
    }
    .campaignInput {
      padding: 15px;
      border: solid 1px #e6e6e6;
      background-color: #ffffff;
    }

    .viewCampaignImage {
      min-height: 300px;
      max-height: 300px;
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: 3px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    .campaignActivateButton {
      border-radius: 4px;
      border: none;
      color: white;
      width: 80%;
      padding: 5px;
      margin-left: auto;
      max-width: 250px;
      margin-right: 10px;
      display: block;
    }
    .activeAndDeleteButton {
      display: flex;
      justify-items: center;
      margin-bottom: 5px;
    }
    .delete {
      color: #e75480;
      padding-top: 4px;
      margin-right: auto;
    }
    .campaignDeleteButton:hover {
      font-size: 1.3em;
    }
  }

  //viewCampaign popup ends

  //Advert preview popup
  .advertPreviewPopup {
    padding: 0px !important;
    margin: 0px !important;
    .popup-content {
      width: 320px !important;
      height: 500px !important;
      padding: 0px !important;
      background-color: transparent !important;
      border: none !important;
    }
    .advertPreviewImage {
      position: absolute;
      width: 320px;
      height: 500px;
      opacity: 1 !important;
    }
    .previewAdImage {
      position: absolute;
      width: 200px;
      height: 200px;
      margin-left: 60px;
      margin-top: 250px;
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: 3px;
      opacity: 1 !important;
    }
    .whiteBackground {
      position: absolute;
      background-color: #f6f6f6;
      opacity: 1 !important;
      width: 230px;
      margin-left: 45px;
      margin-top: 10px;
      border-radius: 15px;
      height: 450px;
    }
  }
  //Advert preview popup ends
}
