@font-face {
  font-family: 'myHelvitikaFont';
  src: url('../../fonts/HELVETICANEUELTPRO-ROMAN.OTF');
}

@mixin text_design($fontSize, $Color, $letterSpacing) {
  font-family: 'myHelvitikaFont';
  font-size: $fontSize; // 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: $letterSpacing; //normal;
  color: $Color; // #f57a08;
}

.billsPage {
  font-family: 'myHelvitikaFontRoman';
  width: auto;
  background-color: #ffffff !important;
  padding: 10px 20px 10px 20px;
  min-height: calc(100vh - 255px);
  overflow-x: auto;

  //comman css classes starts
  .textOverflowDots {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  //comman css classes ends

  .billsPage_filter {
    display: grid;
    grid-template-columns: 90% 10%;
    padding-bottom: 1%;

    .billsPage_filter_filterButton {
      border-radius: 4px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
      border: solid 1px #1d7ef1;
      background-color: #1d7ef1;
      padding: 5px 5px 3px;
      color: #fff;
      font-size: 0.8em;
      font-weight: 100;
      text-align: center;
      cursor: pointer;

      .fa-filter {
        margin-left: 5px;
      }
    }

    .billsPage_filter_filterButton:hover {
      color: #1d7ef1;
      background-color: #fff;
    }
  }

  .billsList {
    width: 100%;

    .billListHeader {
      font-size: 0.9em;
      color: hsl(0, 0%, 65%);
      border-radius: 5px;
      align-items: center;
      padding-bottom: 0px !important;
      background-color: #fff;
      box-shadow: none !important;

      p {
        margin: 0px;
      }
    }

    .billRow {
      background-color: #fff;
      border-radius: 10px;
    }

    .billRowSelected {
      background-color: hsl(209, 100%, 97%);
    }

    .billListCard {
      display: grid;
      grid-template-columns: 5% 20% 15% 15% 10% 10% 15% 10%;
      padding: 10px 0px 10px 10px;
      box-shadow: 0px 2px 8px 2px hsl(0, 0%, 95%);

      .billsListChild {
        height: 24px;
        font-size: 1em;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
      }

      .billsCheckbox {
        width: 23px;
        height: 23px;
        border-radius: 4px;
        border: solid 1px rgba(112, 112, 112, 0.64);
        background-color: #ffffff;
        cursor: pointer;
        color: white;
        text-align: center;
        margin-top: 5%;
      }

      //checkbox ticekd
      .checkBoxTicked {
        background-color: #0084e9;
      }
    }

    .billsListRows {
      display: grid !important;
      row-gap: 15px;
      padding-top: 10px;

      .billsStoreName {
        display: grid;
        row-gap: 10px;
        padding-right: 10px;

        .billsStoreNameLogoAndBrand {
          display: grid;
          grid-template-columns: 30px auto;

          img {
            width: 25px;
            height: 25px;
            object-fit: contain;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.11);
            border: solid 1px #0084e9;
            border-radius: 50%;
          }

          .billsStoreNameLogoAndBrandText {
            text-align: left;
            color: #050505;
            line-height: 1.5;
            font-size: 0.85em;
            color: #050505;
            padding-top: 4px;
          }
        }

        .billsStoreNameDisplayAddress {
          font-size: 0.8em;
          color: #464a53;
        }
      }

      //invoice number css
      .billsListInvoiceNumber {
        font-size: 0.8em;
      }

      //invoice number css ends

      .billsListActionButtons {
        img:first-child {
          margin-right: 10px;
        }

        img {
          width: 17px;
          cursor: pointer;
        }
      }

      //contact number css
      .billsListContactNumber {
        display: grid;
        row-gap: 10px;
        font-size: 0.85em;
        letter-spacing: unset;
        color: #464a53;

        .billsListContactNumberPink {
          color: #ec46c0;
        }
      }

      //contact number css ends

      //Date and Time css
      .billsListDateAndTime {
        font-size: 0.7em;
        color: #464a53;
      }

      //Date and Time css ends

      //bill list amount
      .billsListAmount {
        font-size: 0.8em;
      }

      //bill list amount ends

      //transaction details
      .billsListTransaction {
        font-size: 0.8em;
        display: grid;
        row-gap: 10px;
      }
    }

    //seletced bills info starts
    .billsList_selectedBills_info {
      position: absolute;
      background-color: #0084e9;
      margin-left: -20px;
      padding: 20px 25px;
      width: 100%;
      bottom: 0px;
      display: grid;
      grid-template-columns: 70% 15% 15%;
      color: white;
      margin-left: -20px;

      .billsList_selectedBills_info_trash {
        cursor: pointer;
      }
    }

    //selected bills info ends

    .pagination {
      display: grid;
      width: 40%;
      margin: 0% auto;
      grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
      padding-top: 20px;
      padding-bottom: 100px;
      color: white;

      li {
        text-align: center;
        padding: 5px;
        cursor: pointer;
        background-color: #f5f4f4;
        border: 1px solid white;
      }

      li a {
        color: black !important;
      }

      .pagination_activePage a {
        color: #0084e9 !important;
        border: none !important;
      }
    }

    .header {
      border: 1px solid red;
      background-color: white;
      display: flex;
      height: 10%;
      align-items: center;
    }
  }
}

/*-----------Popup content starts--------------
-----------------------------------------------*/

.billsPopupContent {
  padding: 0%;
  height: 100%;
  display: flex;
  position: relative;
  gap: 2%;

  .leftPart {
    margin: 0px;
    width: 49%;
    border-right: 4px solid #e6e6e6;

    .detailsHeader {
      padding: 5% 15% 10% 5%;
      display: grid;
      grid-template-columns: 25% 75%;

      .detailsHeaderImage img {
        width: 50px;
        height: 50px;
        object-fit: contain;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.11);
        border: solid 1px #0084e9;
        border-radius: 50%;
      }

      .billDetailsPopupBrandName {
        font-size: 0.8em;
      }

      .billDetailsPopupAddress {
        color: #464a53;
        font-size: 0.7em;
        padding-top: 5%;
      }
    }

    //bill details
    .customerAndBillInfo {
      margin: 0% 5% 0% 5%;
    }

    .customerAndBillInfo_infoHeader {
      font-size: 1em;
      border-bottom: 1px solid #707070;
      text-align: center;
    }

    .customerAndBillInfo_billInfo {
      display: grid;
      grid-template-columns: 50% 50%;
      font-size: 0.8em;
      padding-top: 10px;
    }

    .customerAndBillInfo_billInfo_child {
      display: grid;
      row-gap: 5px;
    }

    .customerAndBillInfo_billInfo_left {
      text-align: right;
      color: #43424b;
      padding-right: 20px;
    }

    .customerAndBillInfo_billInfo_right {
      text-align: left;
      padding-left: 10px;
      font-weight: 600;
      a {
        color: #52a7ff;
      }
    }

    //resend button
    .resendButton {
      width: inherit;
      position: absolute;
      bottom: 0px;
      text-align: center;
      color: white;
      background-color: #28c67a;
      display: flex;
      justify-content: space-between;
      padding: 2% 20px;
      align-items: center;

      > div {
        cursor: pointer;
      }

      &.sendBill {
        padding: 20px;
        justify-content: center;
        width: 100%;
      }
      &.billDetailsLeftResend {
        width: 100%;
        position: sticky;
        padding: 4% 20px;
      }
    }

    .resendButton img {
      width: 20px;
      margin-top: -2px;
    }

    /*--------resend bill right part-------------
-------------------------------------------*/
    .billResend_right {
      height: 100%;
      width: 100%;
      position: relative;
      padding: 3% 5% 3% 5% !important;

      .billResend_right_inner {
        height: 100%;
        border-radius: 12px;
        padding: 25% 10% 0% 10%;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.82);
        border: solid 1px #ffffff;
        background-color: #34c47c !important;
        cursor: pointer;
        text-align: center;

        color: white;

        img {
          width: 30%;
          padding-bottom: 10%;
        }

        span {
          display: block;
        }

        .billResend_right_inner_headline {
          font-size: 3em !important;
        }

        .billResend_right_inner_contact {
          font-size: 1em !important;
        }
      }

      .billResend_right_inner:hover {
        img {
          width: 40%;
          padding-bottom: 10%;
        }
      }
    }

    /*--------resend bill right part ends-------------
-------------------------------------------*/

    /*--------resend bill left part-------------
-------------------------------------------*/
    .billResend_left {
      height: 100%;
      position: relative;

      .billResend_left_header {
        text-align: center;
        color: #707070;
        padding-top: 20px;
        font-weight: 600;
        font-size: 20px;
      }
    }

    .billResend_left_input_dialpad_group {
      width: 100%;

      input[type='text'] {
        font-size: 1em;
        width: 80%;
        margin: auto;
        display: block;
        padding: 10px 20px;
        border-radius: 4px;
        border: 2px solid #e6e6e6;
        outline-color: #e6e6e6;
        margin-bottom: 20px;
      }

      .billResend_left_input {
        margin: 2%;
        display: grid;
        grid-template-columns: 85% 15%;
        border-bottom: 1px solid black;

        .billResend_left_input_input {
          text-align: right;
          width: 100%;
          border: none;
          outline-width: 0;
        }

        .billResend_left_input_crossButton {
          color: red;
          text-align: center;
          padding-top: 8px;
          font-size: 1.5em;
          cursor: pointer;
        }
      }

      .billResend_left_dialPad_key {
        border-radius: 8px;
        border: solid 1px #707070;
        color: #707070;
        text-align: center;
        padding: 15px 0px 10px 0px;
        font-size: 1.5em;
        font-weight: 1000;
        cursor: pointer;
      }
    }

    .billResent_email_block {
      display: flex;
      justify-content: center;
      align-items: center;

      input,
      input:focus {
        padding: 10px 20px;
        border: 2px solid #e6e6e6;
        color: #707070;
        border-radius: 4px;
        width: 80%;
      }
    }

    /*--------resend bill left part ends-------------
-------------------------------------------*/
  }

  .rightPart {
    // background-color: #707070;
    height: 500px;
    width: 49%;

    .rightPart_billPart {
      padding: 0% 5% 0% 5%;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      .notFound {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: white;

        h5 {
          margin-top: 5%;
          @include text_design(0.8em, #707070, normal);
        }
      }
    }

    //bill scrollbar
    .rightPart_billPart::-webkit-scrollbar-track {
      background: white;
      border: 4px solid transparent;
      background-clip: content-box;
      /* THIS IS IMPORTANT */
      margin: 5px 0px 5px 0px !important;
    }

    .rightPart_billPart::-webkit-scrollbar {
      width: 10px;
    }

    .rightPart_billPart::-webkit-scrollbar-thumb {
      background: white;
      border: 1px solid rgb(0, 0, 0);
      width: 5px !important;
    }

    .rightPart_billPart_upArrows {
      width: 100%;
      height: 15px;
      margin: 0% auto !important;
      text-align: center;
      overflow-x: hidden;
      overflow-y: hidden;
    }

    .rightPart_billPart_upArrows img {
      width: 12px;
      height: 12px;
      margin-bottom: 0%;
    }

    .rightPart_billPart_downArrows {
      width: 100%;
      height: 15px;
      margin: 0% auto !important;
      text-align: center;
      overflow-x: hidden;
      overflow-y: hidden;
    }

    .rightPart_billPart_downArrows img {
      width: 12px;
      height: 12px;
      margin-top: -4%;
    }

    //star rating
    .rightPart_billPart_starRating {
      padding: 4%;
      background-color: #ffffff;
      text-align: center;
      color: #e5c700;
      font-size: 2em;
    }

    //bill image
    .rightPart_billPart_bill img {
      width: 100%;
    }
  }
}

/*------------Popup content ends here--------------
--------------------------------------------------*/

.billsList_selectedBills_info {
  position: absolute;
  background-color: #0084e9;
  margin-left: -20px;
  padding: 20px 25px;
  width: 100%;
  bottom: 0px;
  display: grid;
  grid-template-columns: 70% 15% 15%;
  color: white;
  margin-left: -20px;

  .billsList_selectedBills_info_trash {
    cursor: pointer;
    width: max-content;
  }
}

.header {
  background-color: white;
  display: flex;
  height: 12%;
  align-items: center;

  .search {
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;

    .auto-complete {
      width: 80%;
    }

    .total_entries {
      @include text_design(0.8em, #707070, normal);
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 2%;
    }

    .search {
      border: 0;
      background-color: #fff;
      font-weight: 900;
      padding: 0;
      width: 40px;
      background: url('../../images/search.svg') no-repeat 10px 11px;
      background-size: 17px;
    }
  }

  .header_btn {
    display: flex;
    width: 50%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 8px;

    .btn {
      display: inline-flex;
      width: 100%;
    }

    .filter {
      width: 18px;
      height: 18px;
      background: url('../../images/filter.svg') no-repeat 0px 0px;
      background-size: 20px;
      display: inline-block;
      margin: auto;
      margin-left: 9%;
    }

    .filter_btn,
    .export_btn {
      width: 20%;
      display: flex;
      align-items: center;

      // margin-right: 3.5%;
      .filter_btn-1 {
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        height: 55%;
        border-radius: 4px;
        padding: 1%;
        align-items: center;
        justify-content: space-evenly;
        margin: 1%;
        cursor: pointer;
        border: 1px solid #797979;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);

        h5 {
          @include text_design(0.8em, #342e2e, 0.8px);
          opacity: 0.8;
          padding-left: 10%;
        }

        .btn_icon {
          width: 15%;
        }
      }

      .export_btn-1 {
        background-color: #c6201a;
        display: flex;
        flex-direction: row;
        height: 55%;
        border-radius: 4px;
        padding: 1%;
        align-items: center;
        justify-content: space-evenly;
        margin: 1%;
        cursor: pointer;
        border: 1px solid #c6201a;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);

        h5 {
          @include text_design(0.8em, #ffffff, 0.4px);
        }

        .btn_icon {
          width: 13%;
        }
      }

      .heading {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 4%;
        @include text_design(1em, #0084e9, 0.4px);
        opacity: 0.8;
      }

      .items {
        position: absolute;
        top: 25%;
        right: 2%;
        width: 12%;
        height: auto;
        box-shadow: 10px 6px 40px 0 rgba(0, 0, 0, 0.2);
        border: solid 0.2px #d1d1d1;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0.5%;

        .item {
          width: 80%;
          display: flex;
          justify-content: flex-start;
          padding: 5%;
          @include text_design(0.8em, #52565f, 0.4px);
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }

    .filter_btn-1:hover {
      background-color: #797979;

      h5 {
        @include text_design(0.8em, #ffffff, 0.4px);
        opacity: 1;
      }
    }

    .export_btn-1:hover {
      background-color: #ffffff;

      h5 {
        @include text_design(0.8em, #c6201a, 0.4px);
      }
    }

    .selected_item {
      width: 28%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2%;

      .item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .item_heading {
          width: 15%;
          padding: 3% 0% 2% 0%;
          @include text_design(0.7em, #797979, 0.4px);
        }

        .item_name {
          display: flex;
          width: 80%;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .item {
            padding: 3% 2% 1%;
            margin-left: 10%;
            @include text_design(0.8em, #000000, 0.4px);
          }

          span {
            width: 15%;
          }

          .item_arrow {
            width: 100%;
          }
        }
      }

      .items {
        position: absolute;
        top: 24%;
        right: 24%;
        width: 175px;
        height: auto;
        box-shadow: 10px 6px 40px 0 rgba(0, 0, 0, 0.2);
        border: solid 0.2px #d1d1d1;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0.5%;

        .item {
          width: 78%;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 12%;
          @include text_design(13px, #52565f, 0.4px);
          cursor: pointer;
        }

        .itemSelected {
          width: 78%;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 12%;
          @include text_design(15px, #0084e9, 0.4px);
          cursor: pointer;
        }

        .item:nth-child(6n) {
          margin-bottom: 3%;
        }

        .itemSelected:nth-child(6n) {
          margin-bottom: 3%;
        }
      }
    }
  }
}

.no-bills {
  padding: 10px;
  text-align: center;
  font-size: 2em;
}

.bill-input {
  div {
    input {
      padding-right: 32px !important;

      &:focus {
        outline: 1px solid #0084e9;
      }
    }
  }

  .searchBox {
    position: absolute;
    width: 40px;
    height: 39px;
    top: 8px;
    right: 0;
    background-color: #5c9aff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: #0084e9;
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: #fff;
      }
    }
  }
}

.dashkaBoard {
  box-shadow: 0px 3px 6px #00000029;
  margin: 16px;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  color: #33475b;
  .container {
    &:last-child {
      border: none;
    }
    &.val-border {
      border-right: 2px solid #dadada;
    }
    .value {
      display: flex;
      font-size: 26px;
      font-weight: 700;
      white-space: pre;
      gap: 16px;
      align-items: baseline;
      .percent {
        font-size: 20px;
        text-align: right;
      }
    }
  }
}
