@font-face {
  font-family: myHelvitikaFont;
  src: url('../../fonts/HELVETICANEUELTPRO-ROMAN.OTF');
}

@mixin text_design($fontSize, $Color, $letterSpacing) {
  font-family: myHelvitikaFont;
  font-size: $fontSize; // 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: $letterSpacing; //normal;
  color: $Color; // #f57a08;
}

.transactionTypes {
  height: 30px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 4px;
}

.inputDatePicker {
  border: 1px solid #ced4da;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 95%;
  height: 38px;
  padding: 1% 2%;
  border-radius: 4px;
  cursor: pointer;

  .startDate,
  .endDate {
    @include text_design(0.7em, #797979, normal);
    opacity: 0.8;
  }
}

.filters {
  margin: 5px 10px !important;
  padding: 15px 0px 15px !important;
  border-radius: 4px !important;
}

.filter-select {
  width: 15% !important;
  padding: 0 !important;
  font-size: 0.7rem;
}
.inputDatePicker {
  width: 250px;
  font-size: 20px;
  .startDate,
  .endDate {
    color: #0077f9 !important;
  }
}

.datePicker {
  position: absolute;
  z-index: 100;
  background-color: white;
  top: 100%;

  .DateRangePicker__CalendarSelection--segment,
  .DateRangePicker__CalendarSelection--start,
  .DateRangePicker__CalendarSelection--end {
    border: #0077f9 !important;
    background-color: #0077f9 !important;
  }

  .DateRangePicker__Date--today--is-highlighted,
  .DateRangePicker__Date--today--is-selected,
  .DateRangePicker__Date--is-highlighted,
  .DateRangePicker__CalendarHighlight--single {
    border: #0077f9 !important;
  }
}

.reset-filters,
.remove-filters {
  color: #c61220;
  text-decoration: underline;
  font-size: 0.8em;
  padding-right: 1em;
  padding-left: 1em;
  cursor: pointer;
  display: inline-flex;
  margin-top: auto;
  margin-bottom: auto;
}

.amtInput {
  width: 20% !important;
}

.amtInput::-webkit-outer-spin-button,
.amtInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.selectOption {
  width: 100%;
}

.statusColor {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  margin: 0% 5% 0% 5%;
}

.typeName {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2%;
  @include text_design(0.95em, #000000, 0.8px);
}
