.userAccess {
  margin: 0 25px 0 25px;
  margin-top: 2%;
  display: flex;

  .contentPart1,
  .contentPart2 {
    z-index: 1;
    width: 100%;
  }

  .contentPart2 {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
  }

  .addUser {
    width: 100%;
    padding: 2rem !important;

    table {
      min-width: 100%;
      margin: 10px 0px 0 10px;
    }
  }

  .heading {
    font-size: 1.3rem;
    font-weight: 700;
    text-align: center;
    padding: 10px 0px;
    color: #43424b;
  }

  .addUserInput {
    margin: 0px 5% 10px 5%;

    &:last-child {
      margin-bottom: 40px;
    }

    label {
      font-size: 12px;
      margin: 10px 0px 5px;
    }

    input {
      margin-top: 4px;
      padding: 15px;
      height: 45px;
      width: 100%;
      border-radius: 6px;
      border: solid 1px #e6e6e6;
      background-color: #ffffff;
    }
  }

  .storePermissionTable {
    margin: 0px 5% 0px 5%;

    td.colorGray {
      color: hsl(0, 0%, 55%);
    }
  }

  .accessName {
    width: 66.9% !important;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #43424b;
    margin: 0px auto;
  }

  th {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #43424b;
  }

  .accessAction,
  .accessRead,
  .accessWrite {
    margin: 0px auto !important;
    text-align: center;
  }

  thead,
  tbody,
  tr {
    width: 100%;
  }

  td {
    padding-top: 4.8% !important;
  }

  .accessBox {
    width: 16.55%;
  }

  .accessBoxCheck {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    border: solid 1px #d2d2d2;
    background-color: #ffffff;
    margin: 0px auto;
  }

  .accessBoxChecked {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    border: solid 1px #d2d2d2;
    background-color: #39d50b;
    margin: 0px auto;
  }

  .addUser .btn {
    border: transparent;
    border-radius: 4px;
    width: 27.6%;
    margin-right: 10px;
    height: 32px;
    color: white;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .addUser .buttonContainer {
    text-align: center;
    margin-top: 4.7%;
  }

  .addUser .submitButton {
    background-image: linear-gradient(259deg, #1ca7ea, #0b59df);
  }

  .addUser .cancelButton {
    background-image: linear-gradient(95deg, #f8b90a, #e15d05);
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.4;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #43424b;
  }

  .storeUsers {
    padding: 2rem 2rem 1rem 4rem !important;
    position: relative;
    flex-grow: 1;

    .usersTable {
      margin: 0;
      margin-top: 3%;
      width: 100%;
      border-collapse: separate;
      position: relative;
      height: 100%;

      .tableHeading {
        display: flex;
        align-items: center;

        span {
          font-weight: 600;
          font-size: 0.9em;
          width: 100%;
          padding: 7px 0px;
        }
      }

      .tableBody {
        padding-top: 10px;
        position: relative;
        overflow-y: auto;
        max-height: 70%;
      }

      .tableItem {
        display: flex;
      }

      .tableItem span {
        color: #a8a8a8;
        width: 100%;
        line-break: auto;
        word-break: break-word;
        padding: 10px 0px;
      }

      .span1 {
        width: 20% !important;
      }
      .span2 {
        max-width: 45% !important;
      }
      .span3 {
        max-width: 35% !important;
        text-align: center;
      }
      .excelth {
        border-radius: 5px;
        border: 1px solid rgb(255, 255, 255);
        color: rgb(123, 125, 128);
        text-align: center;
        cursor: pointer;
        padding: 7px 14px;
        transition: 200ms;

        &:hover {
          background-color: rgb(228, 229, 230);
          border: 1px solid rgb(228, 229, 230);
          color: rgb(86, 87, 88);
        }

        img {
          width: 20%;
          margin-right: 10px;
          max-width: 15px;
          margin-top: -3px;
        }
      }
      .actionBtn {
        text-align: center !important;

        img {
          padding-right: 10px;
          width: 25px;
          vertical-align: middle;
          cursor: pointer;
        }
      }

      @media (max-width: 1400px) {
        .tableHeading span,
        .excelth {
          font-size: 0.7em;
          max-width: 100px;
        }
        .tableItem span {
          font-size: 0.7em;
        }
        .excelth {
          padding: 5px 5px;
        }
        .excelth img {
          max-width: 10px;
          margin-top: 0px;
          margin-left: 3px;
        }
      }
    }
  }

  .storeInfo {
    width: 100%;
    margin-bottom: 2rem;
    display: inline-flex;
    padding: 10px 0;

    .select-stores {
      padding: 0 10px;
      width: 75%;
    }

    .select-user-btn {
      padding: 0 10px;
      width: 25%;
      min-width: 115px;
    }
  }
}

/* scrollbars*/
/* width */
.tableBody::-webkit-scrollbar {
  box-shadow: inset 0 0 1px grey;
  width: 2px;
}

/* Track */
.tableBody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
.tableBody::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
.tableBody::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(to bottom, #1ca7ea, #0b59df);
}
