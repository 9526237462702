body {
  overflow-x: hidden;
  background-color: #f4f5fa;
}

.App,
html,
body,
#root,
#wrapper {
  height: 100%;
  width: 100%;
}

#wrapper {
  overflow: hidden;
}

#sidebar-wrapper {
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  border: none;
  border-right: 1px solid #e0e4e4 !important;
  background-color: #ffffff !important;
  min-width: 250px;
}

.sidebarWrapperInside {
  position: sticky;
  top: 0;
  overflow-x: hidden;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0px;
}

@font-face {
  font-family: myHelvitikaFont;
  src: url('../fonts/HelveticaNeueLTPro-Md.ttf');
}
@font-face {
  font-family: myHelvitikaFontRoman;
  src: url('../fonts/HELVETICANEUELTPRO-ROMAN.OTF') format('opentype');
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .content-wrapper {
    min-width: 1024px;
  }

  #page-content-wrapper {
    overflow-x: scroll;
  }
}

.sidebarLinks {
  padding: 2.5% 0px;
  transition: all 0.3s;

  &:hover {
    background-color: hsl(210, 17%, 97%);
  }
}

.selectedLink {
  background-color: hsl(210, 17%, 97%);
}

.sidebarLinks a {
  text-decoration: none;
  padding-bottom: 0;
  padding-right: 0;
}

#sidebarList {
  list-style-type: none;
  width: 100%;
  padding: 0px;
  max-height: calc(100vh - 160px);
  padding-bottom: 30px;
  overflow-y: auto;
}
.nav-link {
  text-decoration: none !important;
  display: grid;
  grid-template-columns: 20% 80%;
}

.sidebarLinkImages {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebarLinkImage {
  width: 35%;
  margin: 10%;
  max-width: 150px;
}
.sidebarLinkText {
  font-size: 75%;
  font-weight: 450;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: hsl(248, 4%, 41%);
  vertical-align: middle;
  padding: 5%;
  font-family: myHelvitikaFont;
}

#sidebarChildList .sidebarLinkText {
  margin-left: 55px;
  &:hover {
    color: #2591ff;
  }
}

.sidebarLinkTextColor {
  color: #2591ff;
}

#sidebarList > li > a > i {
  padding: 5px !important;
}

#sidebarChildList {
  padding: 0;
  list-style-type: none;

  .nav-link {
    grid-template-columns: 100%;
    padding: 0;
  }
}

.sidebar-heading {
  width: 100%;
  .sidebar-header-head {
    background: #fff;
    width: auto;
    height: 40px;
    margin: 10px 15px;
    display: flex;

    .title {
      margin: auto 0;
      margin-left: 20px;
      font-size: 1em;
    }
  }
  .sidebar-header-body {
    height: 160px;
    padding: 10px 10px 10px 20px;
    background: #fff;
    border-bottom: 1px solid #e0e4e4 !important;
    // border-top: 1px solid #e0e4e4 !important;
  }

  .sidebar-heaeder-fade {
    color: #858997;
  }
  .blue {
    color: #007ef1;
  }
  .sidebar-text {
    font-size: 0.65em;
    width: auto;
  }
  .sidebar-header-selected {
    display: flex;
    font-size: 0.9em;
    margin-top: 10px;
  }
  .sidebar-header-column-1 {
    width: 55%;
  }
  .sidebar-header-column-2 {
    display: flex;
    width: 45%;
  }
  .sidebar-header-link {
    background: #007ef1;
    margin-top: auto;
    margin-bottom: auto;
    color: #fff;
    font-size: 0.65em;
    border-radius: 20px;
    padding: 5px 15px;
    margin-left: auto;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      font-weight: bold;
    }
  }
  .sidebar-brands {
    padding: 10px 0 15px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    min-height: 60px;
    cursor: pointer;
    max-width: 250px;
  }
  .brand-image {
    border: 1px solid #707070;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }
}

#sidebar-wrapper {
  z-index: 44;
  /*-----------Popups--------------*/
  .popup-content {
    // width: 60% !important;
    z-index: 44;
  }

  .popupModalHeading {
    width: 100%;
    font-size: 120%;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.8px;
    text-align: center;
    color: #2591ff;
    margin-bottom: 5%;
  }
  .popupModalEditButton img {
    cursor: pointer;
    width: 2%;
    margin-top: -0.5%;
  }
  .popupModalGrids {
    margin-bottom: 2%;
    overflow-y: scroll !important;
    height: 300px !important;

    .infinite-scroll-component {
      display: grid;
      grid-template-columns: auto auto auto;
    }
  }

  .storeSelectorCard {
    text-align: center;
    margin: 2%;
  }

  .greenBorder {
    border: 1px solid green;
  }

  /* scrollbars*/
  /* width */
  .popupModalGrids::-webkit-scrollbar {
    box-shadow: inset 0 0 1px grey;
    width: 2px;
  }

  /* Track */
  .popupModalGrids::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 10px;
  }

  /* Handle */
  .popupModalGrids::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }

  /* Handle on hover */
  .popupModalGrids::-webkit-scrollbar-thumb:hover {
    background-image: linear-gradient(to bottom, #1ca7ea, #0b59df);
  }

  .popupModalGridInner input {
    width: 70%;
    height: 40px;
    padding-left: 10px;
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    margin-left: 15%;
    font-size: 100%;
    font-weight: 420;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #43424b;
    margin-bottom: 5%;
  }

  .popupModalGridInner select {
    width: 70%;
    height: 40px;
    padding-left: 10px;
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    margin-left: 15%;
    font-size: 100%;
    font-weight: 420;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #43424b;
    margin-bottom: 5%;
  }

  .popupModalGridInner textarea {
    width: 70%;
    padding-left: 10px;
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    margin-left: 15%;
    font-size: 100%;
    font-weight: 420;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #43424b;
    margin-bottom: 5%;
  }

  .popupModalLabel {
    opacity: 0.4;
    font-size: 90%;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-left: 16%;
    color: #43424b;
    margin-bottom: 1%;
  }

  .popupModalSubmitButton {
    width: 20%;
    padding: 0.8%;
    border-radius: 5%;
    border: none;
    background-image: linear-gradient(259deg, #1ca7ea, #0b59df);
    font-size: 75%;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-left: 40%;
  }
  .close {
    width: 10%;
    cursor: pointer;
    position: absolute;
    display: block;
    right: -5%;
    top: -6%;
    font-size: 24px;
    opacity: 1 !important;
  }
  /*store selector*/
  .storeSelectorCard img {
    width: 70%;
    max-width: 200px;
  }
  .storeSelectorBrandName {
    font-size: 85%;
    font-weight: 450;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #43424b;
  }
  .storeSelectorDisplayAddress {
    font-size: 80%;
    font-weight: 420;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #43424b;
  }
  .storeSelectorCompanyName {
    opacity: 0.6;
    font-size: 70%;
    font-weight: 420;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #43424b;
  }
}

/* scrollbars*/
/* width */
#sidebarList::-webkit-scrollbar {
  box-shadow: inset 0 0 1px grey;
  width: 2px;
}

/* Track */
#sidebarList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
#sidebarList::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
#sidebarList::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(to bottom, #1ca7ea, #0b59df);
}

//loading overlay
.loadingOverlay {
  width: 100% !important;
}
._loading_overlay_overlay {
  width: 100% !important;
  background-color: transparent;
}
._loading_overlay_content {
  position: sticky !important;
  top: 30% !important;
  bottom: 60% !important;
}

.sidebar-header-body {
  &:hover .brand-card {
    opacity: 1;
    visibility: visible;
  }
  &:hover .sidebar-brands .brand-image {
    opacity: 0;
  }
}

.brand-card {
  position: fixed;
  top: 0;
  opacity: 0;
  visibility: hidden;
  left: 250px;
  width: auto;
  min-width: 380px;
  background: #fff;
  height: auto;
  border-left: 3px solid #0081ed;
  -webkit-box-shadow: 0px 0px 3px 1px rgba(194, 194, 194, 1);
  -moz-box-shadow: 0px 0px 3px 1px rgba(194, 194, 194, 1);
  box-shadow: 0px 0px 3px 1px rgba(194, 194, 194, 1);

  -webkit-transition: opacity 0.25s ease-in;
  -moz-transition: opacity 0.25s ease-in;
  -ms-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;

  .brand-card-head {
    padding: 20px;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 2px solid #707070;
  }

  .title {
    font-size: 1.1em;
    margin-top: auto;
    margin-bottom: auto;
  }
  .brand-card-body {
    padding: 20px 0 10px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    height: auto;
    max-height: 200px;
    max-width: 650px;
    display: flex;
    background: #fff;

    .brand-card-item-heading {
      padding: 10px 0px 10px 10px;
      text-align: center;
      height: auto;
      width: auto;
      flex: 0 0 auto;
    }
    .item-heading-text {
      font-size: 0.8em;
      margin-top: 5px;
      color: #797979;
      &:first-child {
        margin-top: 50px;
      }
    }
    .brand-card-item {
      padding: 10px 20px;
      text-align: center;
      height: auto;
      width: auto;
      flex: 0 0 auto;
    }

    .brand-card-image {
      border: 1px solid #707070;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      position: relative;
      -webkit-box-shadow: 0px 0px 3px 1px rgba(194, 194, 194, 1);
      -moz-box-shadow: 0px 0px 3px 1px rgba(194, 194, 194, 1);
      box-shadow: 0px 0px 3px 1px rgba(194, 194, 194, 1);
    }
    .brand-name {
      margin-top: 10px;
      font-size: 0.8em;
    }
    .brand-card-row {
      display: table-row;
    }
    .brand-count {
      margin-top: 5px;
      font-size: 0.8em;
    }
  }
}
//not selectable text global
.notSelectableText {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
