.navbar {
  height: 60px;
  margin: 0px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
}

.profileImageNav {
  border-radius: 50%;
  width: 32px;
  margin-right: 8px;
}

.navProfile {
  display: inline-block;
}
#navbarDropdown {
  z-index: 1;
  // float: right;
  color: #000;
  font-size: 75%;
}

.nav-link::after {
  display: none;
}
.navbar-nav .dropdown-menu {
  position: absolute;
}
