.manage-stores {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .btn-success {
    background: #63c64c;
    color: #fff;
    border: 1px solid #63c64c;
    padding: 0.375rem 1.3rem;
    &:hover {
      color: #63c64c;
      background: #fff;
    }
  }
  .btn-outline-dark {
    border: 1px solid #707070;
    color: #707070;
    &:hover {
      color: #fff;
    }
  }
  .btn-borderless {
    border: 1px solid transparent;
    color: #3370d1;
    text-decoration: underline;
    &:focus,
    &:active {
      outline: 1px solid transparent;
      border: 1px solid transparent;
      box-shadow: none;
    }
  }

  .input-group {
    z-index: 11;
    border-radius: 0.25rem;
    border: 1px solid #707070;

    .input-group-prepend {
      border-right: 0;
      background: #ffffff;
    }
    .input-group-text {
      border: 0;
      background-color: #fff;
      font-weight: 900;
      padding: 0;
      width: 40px;
      background: url('../../images/search.svg') no-repeat 10px 11px;
      background-size: 17px;
    }
    input {
      border-left: 0;
      border: 0;
      padding: 0.475rem 0.75rem 0.475rem 0em;
      height: 40px;
      font-size: 12px;

      &::placeholder {
        font-size: 12px;
      }
    }
    .form-control:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
  }
  .auto-complete {
    z-index: 11;
    position: relative;
    .filter-auto-complete {
      border: 1px solid #797979;
      border-top: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      position: absolute;
      background: #fff;
      z-index: 10;
      top: 40px;
      width: 100%;
      -webkit-box-shadow: 0px 0px 3px 1px rgba(194, 194, 194, 1);
      -moz-box-shadow: 0px 0px 3px 1px rgba(194, 194, 194, 1);
      box-shadow: 0px 0px 3px 1px rgba(194, 194, 194, 1);
    }
    .filter-auto-complete-item {
      display: inline-flex;
      cursor: pointer;
      width: 100%;
      padding: 13px 19px;
      border-bottom: 1px solid #e2e2e2;
    }
    .filter-auto-complete-image {
      width: 25px;
      height: 25px;

      img {
        width: inherit;
        height: inherit;
        border: 1px solid #0089f9;
        border-radius: 50%;
      }
    }
    .filter-auto-complete-label {
      margin-left: 20px;
      font-size: 0.9em;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .manage-stores-content {
    background: #fff;
    padding: 1rem;
  }

  .filter {
    width: 20px;
    height: 20px;
    background: url('../../images/filter.svg') no-repeat 0px 0px;
    background-size: 20px;
    margin: auto;
  }
  .add-filter,
  .filter-by-icon {
    display: inline-flex;
    border: 1px solid #707070;
    width: 30px;
    height: 30px;
    min-width: 30px;
  }
  .btn-outline-dark {
    display: inline-flex;
  }

  .reset-filters,
  .remove-filters {
    color: #c61220;
    text-decoration: underline;
    font-size: 0.8em;
    padding-right: 1em;
    padding-left: 1em;
    cursor: pointer;
    display: inline-flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .filter-col {
    display: inline-flex;
  }
  .stores-count {
    color: #707070;
    font-size: 0.8em;
    margin: auto 8px;
  }

  .filter-list {
    display: inline-flex;
    width: calc(100% - 30px);
    flex-wrap: wrap;
  }
  .filter-item {
    padding: 0.275em 0.65em;
    border-radius: 3px;
    background-color: #006eff;
    color: #fff;
    font-size: 0.9em;
  }

  .remove-filter {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    margin-left: 5px;
    font-style: normal;
    font-weight: 200;
  }

  .add-filter {
    background-color: #707070;
    cursor: pointer;
  }

  .add-icon {
    width: 20px;
    height: 20px;
    border: 0;
    background: url('../../images/add.svg') no-repeat 0px 0px;
    background-size: 19px;
    margin: auto;
  }

  .table-title {
    background: #f5f4f4;
    display: inline-flex;
    padding: 0.375em 0.75em;
    height: 48px;
    width: 100%;
  }

  .total {
    font-size: 0.7em;
    color: #707070;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0.5em;
  }

  .custom-control-label::before,
  .custom-control-label::after {
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    left: -2rem;
  }

  .selected-stores,
  .select-all-stores,
  .store-image,
  .store-name {
    margin-top: auto;
    margin-bottom: auto;
  }

  .store-image {
    height: 25px;
    width: 25px;
  }

  .brand-image {
    height: inherit;
    width: inherit;
    border: 1px solid #006eff;
    border-radius: 50%;
  }

  .company-name {
    border-bottom: 1px solid #707070;
    width: 100%;
    text-transform: uppercase;
    padding-bottom: 5px;
    margin: 12px 0px 12px 20px;
  }

  .store-item {
    padding-left: 55px;
    .custom-control-label {
      display: inline-flex;
      margin: 15px 0px;
    }
    .store-name {
      font-size: 0.9em;
    }
  }

  .column {
    width: 30%;
    flex: 0 0 auto;
  }

  .company-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
  }

  .company-item {
    max-width: 250px;
    word-wrap: break-word;
    word-break: break-all;

    .custom-control {
      padding-left: 1.3rem;
    }
    .custom-control-label::before,
    .custom-control-label::after {
      left: -2.2rem;
    }
  }
  .item-padding {
    padding: 27px;
  }
}

.popup-content {
  width: 55%;
}

.filter-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  .filter-modal-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f5f8;

    .header {
      background-color: transparent;
      font-size: 20px;
      font-weight: 600;
      padding: 16px;
      color: #33475b;
      span {
        color: #0081ed;
      }
    }
  }

  .filter-modal-body {
    padding: 0 10px;
    font-size: 1em;
    .filter-option {
      display: inline-flex;
      margin: 10px !important;
      background: #f5f4f4;
      padding: 15px 5px 20px;
      border-radius: 3px;
      width: calc(100% - 20px);
      box-shadow: 0px 3px 6px #00000029;

      &.selected {
        border-left: 6px solid #0081ed;
        background: #fff;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .label {
        margin-bottom: 12px;
        font-size: 0.9em;
        color: #707070;
      }

      .custom-control {
        width: 100%;
        padding-right: 20px;
      }
      .custom-control-label {
        width: 100%;
      }

      .sub-option,
      .sub-options {
        display: inline-flex;
        align-items: center;
      }
      .sub-options {
        width: 100%;
        * {
          margin-top: auto;
          margin-bottom: auto;
        }
      }
      .sub-option {
        width: 50%;
      }
      .filter-select {
        width: 10%;
      }
      .grey {
        color: #797979;
        font-size: 0.8em;
      }
      .control-flex {
        display: flex;
        .custom-control-label {
          width: 35%;
        }
        .additional-css {
          color: #9a9a9a;
          border-right: 2px solid #e6e6e6;
          margin-right: 20px;
          font-weight: 600;
        }
      }
    }
  }

  .filter-modal-footer {
    display: inline-flex;
    height: 65px;
    background-color: #f2f5f8;
    width: 100%;
    margin-top: 5px;
    justify-content: space-between;
    .cancel {
      border: 1px solid #707070;
    }

    .proceed {
      background: #63c64c;
      color: #fff;
      border: 1px solid #63c64c;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(85, 175, 106, 0.5);
      }

      &:active {
        background-color: #1e7e34;
        border-color: #1c7430;
      }
    }
    .custom-control,
    .filter-buttons {
      margin-bottom: auto;
      font-size: 0.9em;
      margin-top: auto;
    }
  }
}
