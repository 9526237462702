@font-face {
  font-family: 'Helvetica Neue LT Pro 65';
  src: url('../../fonts/HelveticaNeueLTPro-Md.woff2') format('woff2'),
    url('../../fonts/HelveticaNeueLTPro-Md.woff') format('woff'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url('../../fonts/HelveticaNeueLTPro-Md.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}
.App,
html,
body,
#root {
  height: 100%;
  width: 100%;
}
.loginScreen,
.signupScreen {
  .App,
  html,
  body,
  #root {
    height: 100%;
    width: 100%;
  }

  .loginLeft {
    background-image: linear-gradient(to bottom, #1ca7ea, #0c5ddf);
    display: flex;
  }

  .loginLeft img {
    width: 70%;
    align-content: center;
    margin: auto;
  }

  .loginRight {
    padding: 0%;
  }

  .loginLogo {
    width: 6%;
    height: 6%;
    margin: 1.5rem 1.5rem 0rem 1.5rem;
  }

  .loginInputField input {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    outline: none !important;
    margin-left: 3%;
    background: #f4f5fa !important;
    width: 100%;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .loginInputFieldError input {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    outline: none !important;
    background: #f4f5fa !important;
    margin-left: 3%;
    width: 100%;
  }

  .loginInputField input:-webkit-autofill,
  .loginInputFieldError input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #f4f5fa inset;
    box-shadow: 0 0 0px 1000px #f4f5fa inset;
  }

  .loginInputField,
  .loginInputFieldError {
    border-bottom: 1px solid #1ca7ea;
    margin-bottom: 1rem;
    height: 25px;
    padding: 5px 0;
    width: 100%;
    font-size: 75%;
    display: inline-flex;
  }

  .loginInputFieldError {
    border-bottom: 1px solid #ea1c1c;
  }

  .loginForm {
    width: 35%;
    margin: auto;
    margin-top: 15%;
  }
  @media screen and (max-width: 640px) {
    .loginForm {
      width: 80%;
      margin: auto;
      margin-top: 4rem;
    }
  }
  .loginLabel {
    color: grey;
    font-size: 75%;
    text-align: left;
  }

  .loginButton {
    width: 70%;
    background-image: linear-gradient(to bottom right, #1ca7ea, #0c5ddf);
    margin-top: 1rem;
  }
  .buttonText {
    padding-right: 5%;
    padding-left: 5%;
  }

  .authHeading {
    font-size: 1.2rem;
    font-weight: bold;
  }
  form {
    margin-top: 1.5rem;
  }
  .fa-phone {
    transform: rotate(90deg);
  }
  /* 1ca7ea,0c5ddf  */
}

.loginScreen {
  .loginForgot {
    font-size: 75%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .loginForgot2 {
    color: #1ca7ea;
    text-align: right;
    float: right;
  }

  .loginForgot1 {
    color: black;
    text-align: left;
  }

  .resetForgot {
    font-size: 75%;
    margin-top: 1rem;
    color: #1ca7ea;
  }
  .loginErrorMessage {
    color: red;
    font-size: 75%;
    display: block;
  }

  .goBackFromConfirmation {
    cursor: pointer;
    color: #1ca7ea;
    text-align: center;
    padding-top: 2%;
  }
}

.signupScreen {
  .loginForgot {
    font-size: 0.75rem;
    margin-top: 1rem;
    color: #1ca7ea;
  }
}

.updateBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  .skipButton {
    margin-right: 3%;
  }
}
