.aboutUs {
  width: 80%;
  margin: 0 auto;
  margin-top: 3%;
  .aboutHeading {
    height: 32px;
    font-family: Helvetica;
    font-size: 120%;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.4px;
    text-align: center;
    color: #117be6;
  }
}
